import React from "react";
import "./Banner.scss";
import { images } from "../../images";

export const Banner = ({
  breadcrumbWord1,
  breadcrumbWord2,
  breadcrumbWord3,
  breadcrumbWord4,
  link1,
  link2,
  link3,
  disabled,
  background = images.banner01.image,
  bgSize = "contain",
}) => {
  return (
    <>
      <main>
        <section
          className="common_banner"
          // style={{
          //   backgroundImage: `url(${background})`,
          //   backgroundSize: bgSize,
          // }}
        >
          {/* <div className="breadcrumb_wrapper">
            <div className="common_breadcrumb_line1">
              <Link to={link1 ? link1 : ""} className="common_breadcrumb_link">
                {breadcrumbWord1} &nbsp;
              </Link>
              <Link
                to={link2 ? link2 : ""}
                className={`common_breadcrumb_link ${
                  disabled ? "disabled_link" : ""
                }`}
              >
                {breadcrumbWord2 ? `| ${breadcrumbWord2} ` : ""}
              </Link>
              <Link to={link3 ? link3 : ""} className="common_breadcrumb_link">
                {breadcrumbWord3 ? `| ${breadcrumbWord3}` : ""}
              </Link>
            </div>
            <div className="common_breadcrumb_line2">
              {breadcrumbWord4?.toLowerCase()}
            </div>
          </div> */}
        </section>
      </main>
    </>
  );
};

// export default Banner;
