export { default as logo } from "./general/asre-eng.webp";
export { default as logowhite } from "./general/asre-eng-white.svg";
export { default as contactfooter } from "./general/contact-icon.svg";
export { default as emailfooter } from "./general/email-icon.svg";
export { default as whatsapp } from "./general/whatsapp.svg";
export { default as mailicon } from "./general/mail-icon.svg";
export { default as mobileenquire } from "./general/enquirebackground.svg";
export { default as enquirewhatsapp } from "./general/whatsapp mobile (2).svg";
export { default as downarroww } from "./general/down-arrow-w.svg";
export { default as downarrowb } from "./general/black-arrow.svg";
export { default as whitedownarrow } from "./general/white-arrow.svg";
export { default as calliconblack } from "./general/callicon.svg";
export { default as emailiconwhite } from "./general/email-icon-white.svg";
export { default as rightarrowblue } from "./general/rightarrow-blue.svg";
export { default as isocertificate } from "./general/ASREENGINEERING227272023.pdf";

// Contact Page
export { default as location } from "./Contact/location.svg";
export { default as call } from "./Contact/call.svg";
export { default as mail } from "./Contact/mail.svg";

// header images
export { default as rightarw } from "./header/rightarw.svg";
export { default as downarw } from "./header/downarw.svg";

// footer images
export { default as callblack } from "./general/call.svg";
export { default as mailblack } from "./general/email.svg";
export { default as callwhite } from "./general/call-footer-white.svg";
export { default as mailwhite } from "./general/email-footer-white.svg";
export { default as fbicon } from "./general/fb-icon.svg";
export { default as catalogueicon } from "./general/e-catalogue-icon.svg";
export { default as yticon } from "./general/youtube-icon.svg";
export { default as twticon } from "./general/twitter-icon.svg";
export { default as linkedinicon } from "./general/linkedin-icon.svg";
export { default as pinteresticon } from "./general/pinterest-logo.svg";
export { default as eBrochure } from "./general/strainer-manufacturers-catalogue.pdf";

//contact images
export { default as locationicon } from "./Contact/location-icon.svg";
export { default as callicon } from "./Contact/call-icon.svg";
export { default as mailiconcontact } from "./Contact/mail-icon.svg";

export let images = {
  //New images
  banner01: {
    image: require("./Homepage/banner-01.jpg"),
    alt: "banner",
  },
  banner01mob: {
    image: require("./Homepage/banner-01-mob.jpg"),
    alt: "banner",
  },
  banner02: {
    image: require("./Homepage/banner-02.jpg"),
    alt: "banner",
  },
  banner02mob: {
    image: require("./Homepage/banner-02-mob.jpg"),
    alt: "banner",
  },
  banner03: {
    image: require("./Homepage/banner-03.jpg"),
    alt: "banner",
  },
  banner03mob: {
    image: require("./Homepage/banner-03-mob.jpg"),
    alt: "banner",
  },
  banner04: {
    image: require("./Homepage/banner-04.jpg"),
    alt: "banner",
  },
  banner04mob: {
    image: require("./Homepage/banner-04-mob.jpg"),
    alt: "banner",
  },
  homeAbout: {
    image: require("./Homepage/home-about.webp"),
    alt: "about us",
  },
  homeAboutJpg: {
    image: require("./Homepage/home-about.jpg"),
    alt: "about us",
  },
  homeAboutmob: {
    image: require("./Homepage/home-about-mob.webp"),
    alt: "about us",
  },
  homeAboutJpgmob: {
    image: require("./Homepage/home-about-mob.jpg"),
    alt: "about us",
  },
  industrySegment: {
    image: require("./Homepage/home-background.webp"),
    alt: "Industry Segment",
  },
  industrySegmentjpg: {
    image: require("./Homepage/home-background.jpg"),
    alt: "Industry Segment",
  },
  secretmanufacturer: {
    image: require("./Homepage/manufacturing.png"),
    alt: "manufacturer",
  },
  secretstock: {
    image: require("./Homepage/in-stock.png"),
    alt: "stock",
  },
  secretquick: {
    image: require("./Homepage/high-speed.png"),
    alt: "quick response",
  },
  secretontimedelivery: {
    image: require("./Homepage/fast-delivery.png"),
    alt: "on time delivery",
  },
  secretquality: {
    image: require("./Homepage/quality-policy.png"),
    alt: "quality",
  },
  segmentWaterPipe: {
    image: require("./Homepage/water-pipe1.png"),
    alt: "Water Pipe",
  },
  segmentDistillatory: {
    image: require("./Homepage/distillatory.png"),
    alt: "Distillatory",
  },
  segmentBrewing: {
    image: require("./Homepage/brewing.png"),
    alt: "Brewing",
  },
  segmentPulppaper: {
    image: require("./Homepage/pulp-paper.png"),
    alt: "Pulp Paper",
  },
  segmentFactoryplant: {
    image: require("./Homepage/factory-plant4.png"),
    alt: "Factory plant",
  },
  segmentSurrey: {
    image: require("./Homepage/Surrey.png"),
    alt: "Surrey",
  },
  segmentWatertreatment: {
    image: require("./Homepage/water-treatment.png"),
    alt: "water Treatment",
  },
  segmentVarnish: {
    image: require("./Homepage/varnish-icon.png"),
    alt: "Varnish",
  },
  segmentsteel: {
    image: require("./Homepage/steel.png"),
    alt: "steel",
  },
  segmentChemicalind: {
    image: require("./Homepage/chemical-ind.png"),
    alt: "chemical Ind",
  },

  // Contact us images
  contactban: {
    image: require("./Contact/contact-banner.jpg"),
    alt: "banner",
  },
  globalpresence: {
    image: require("./Contact/global-presence-SM.jpg"),
    alt: "global presence",
  },

  // Gallery Images starts

  gallery1: {
    image: require("./Homepage/gallery/gallery-01.webp"),
    alt: "gallery1",
  },
  gallery1jpg: {
    image: require("./Homepage/gallery/gallery-01.jpg"),
    alt: "gallery1",
  },
  gallery2: {
    image: require("./Homepage/gallery/gallery-02.webp"),
    alt: "gallery2",
  },
  gallery2jpg: {
    image: require("./Homepage/gallery/gallery-02.jpg"),
    alt: "gallery2",
  },
  gallery3: {
    image: require("./Homepage/gallery/gallery-03.webp"),
    alt: "gallery3",
  },
  gallery3jpg: {
    image: require("./Homepage/gallery/gallery-03.jpg"),
    alt: "gallery3",
  },
  gallery4: {
    image: require("./Homepage/gallery/gallery-04.webp"),
    alt: "gallery4",
  },
  gallery4jpg: {
    image: require("./Homepage/gallery/gallery-04.jpg"),
    alt: "gallery4",
  },
  gallery5: {
    image: require("./Homepage/gallery/gallery-05.webp"),
    alt: "gallery5",
  },
  gallery5jpg: {
    image: require("./Homepage/gallery/gallery-05.jpg"),
    alt: "gallery5",
  },

  // General images
  termsban: {
    image: require("./general/T&C-banner.jpg"),
    alt: "terms and conditions",
  },
  isocertified: {
    image: require("./general/iso-certified-logo.png"),
    alt: "ISO 9001 - 2015 certified",
  },
  leftarrowblue: {
    image: require("./general/leftarrow-blue.png"),
    alt: "left arrow blue",
  },

  // About Us images
  aboutHero: {
    image: require("./About/hero-img.webp"),
    alt: "About Us Hero",
  },
  aboutHeroJpg: {
    image: require("./About/hero-img.jpg"),
    alt: "About Us Hero",
  },
  aboutHeroMob: {
    image: require("./About/hero-img-mob.webp"),
    alt: "About Us Hero",
  },
  aboutHeroJpgMob: {
    image: require("./About/hero-img-mob.jpg"),
    alt: "About Us Hero",
  },
  mission: {
    image: require("./About/mission-icon.png"),
    alt: "Mission",
  },
  Vision: {
    image: require("./About/vision-icon.png"),
    alt: "Vision",
  },
  Design: {
    image: require("./About/design-icon.png"),
    alt: "Design",
  },
  Worker: {
    image: require("./About/worker-icon.png"),
    alt: "Worker",
  },
  Quality: {
    image: require("./About/quality-icon.png"),
    alt: "Quality",
  },
  Sales: {
    image: require("./About/sales-icon.png"),
    alt: "Sales",
  },
  Service: {
    image: require("./About/service-icon.png"),
    alt: "Service",
  },

  //Gallery Images ends

  // Product images
  // Product images

  baskettypeImg: {
    image: require("./products/basket-strainer.jpg"),
    alt: "Basket Strainer",
  },
  ytypeImg: {
    image: require("./products/y-strainer.jpg"),
    alt: "y-strainer",
  },
  ttypeImg: {
    image: require("./products/t-strainer.jpg"),
    alt: "t-strainer",
  },
  suctiondiffuser: {
    image: require("./products/suction-diffuser.jpg"),
    alt: "suction-diffuser",
  },
  sandfilter: {
    image: require("./products/sand-filter.jpg"),
    alt: "sand-filter",
  },
  magneticstrainer: {
    image: require("./products/magnetic-strainer.jpg"),
    alt: "magnetic-strainer",
  },
  duplexstrainer: {
    image: require("./products/duplex-strainer.jpg"),
    alt: "duplex-strainer",
  },
  conicalstrainer: {
    image: require("./products/conical-strainer.jpg"),
    alt: "conical-strainer",
  },
  cartridgefilter: {
    image: require("./products/cartridge-filter.jpg"),
    alt: "cartridge-filter",
  },
  bagfilter: {
    image: require("./products/bag-filter.jpg"),
    alt: "bag-filter",
  },
  selfcleaning: {
    image: require("./products/self-cleaning.jpg"),
    alt: "self-cleaning",
  },
  madetoorder: {
    image: require("./products/made-to-order.jpg"),
    alt: "made-to-order",
  },

  // Product images
  // Product images

  // HOMEPAGE GALLERY IMAGES
  // HOMEPAGE GALLERY IMAGES
  // HOMEPAGE GALLERY IMAGES

  // Basket Strainers images
  // Basket Strainers images
  // Basket Strainers images

  homeBS1: {
    image: require("./BS/Basket-Type-Strainer-1.jpg"),
    alt: "basket strainer",
  },
  homeBS2: {
    image: require("./BS/Basket-Type-Strainer-2.1.jpg"),
    alt: "basket strainer",
  },
  homeBS3: {
    image: require("./BS/Basket-Type-Strainer-2.2.jpg"),
    alt: "basket strainer",
  },
  homeBS4: {
    image: require("./BS/Basket-Type-Strainer-2.jpg"),
    alt: "basket strainer",
  },
  homeBS5: {
    image: require("./BS/Basket-Type-Strainer-3.jpg"),
    alt: "basket strainer",
  },
  homeBS6: {
    image: require("./BS/Basket-Type-Strainer-4.jpg"),
    alt: "basket strainer",
  },
  homeBS7: {
    image: require("./BS/Basket-Type-Strainer-5.jpg"),
    alt: "basket strainer",
  },
  homeBS8: {
    image: require("./BS/Basket-Type-Strainer-6.jpg"),
    alt: "basket strainer",
  },
  homeBS9: {
    image: require("./BS/Basket-Type-Strainer-7.jpg"),
    alt: "basket strainer",
  },
  homeBS10: {
    image: require("./BS/Basket-Type-Strainer-8.jpg"),
    alt: "basket strainer",
  },
  homeBS11: {
    image: require("./BS/Basket-Type-Strainer-9.jpg"),
    alt: "basket strainer",
  },
  homeBS12: {
    image: require("./BS/Basket-Type-Strainer-10.jpg"),
    alt: "basket strainer",
  },
  homeBS13: {
    image: require("./BS/Basket-Type-Strainer-11.jpg"),
    alt: "basket strainer",
  },
  homeBS14: {
    image: require("./BS/Basket-Type-Strainer-12.jpg"),
    alt: "basket strainer",
  },
  homeBS15: {
    image: require("./BS/Basket-Type-Strainer-13.jpg"),
    alt: "basket strainer",
  },
  homeBS16: {
    image: require("./BS/Basket-Type-Strainer-14.jpg"),
    alt: "basket strainer",
  },
  homeBS17: {
    image: require("./BS/Basket-Type-Strainer-15.jpg"),
    alt: "basket strainer",
  },
  homeBS18: {
    image: require("./BS/Basket-Type-Strainer-16.jpg"),
    alt: "basket strainer",
  },
  homeBS19: {
    image: require("./BS/Basket-Type-Strainer-17.jpg"),
    alt: "basket strainer",
  },
  homeBS20: {
    image: require("./BS/Basket-Type-Strainer-18.jpg"),
    alt: "basket strainer",
  },
  homeBS21: {
    image: require("./BS/Basket-Type-Strainer-19.jpg"),
    alt: "basket strainer",
  },
  homeBS22: {
    image: require("./BS/Basket-Type-Strainer-20.jpg"),
    alt: "basket strainer",
  },
  homeBS23: {
    image: require("./BS/Basket-Type-Strainer-21.jpg"),
    alt: "basket strainer",
  },
  homeBS24: {
    image: require("./BS/Basket-Type-Strainer-22.jpg"),
    alt: "basket strainer",
  },
  homeBS25: {
    image: require("./BS/Basket-Type-Strainer-23.jpg"),
    alt: "basket strainer",
  },
  homeBS26: {
    image: require("./BS/Basket-Type-Strainer-24.jpg"),
    alt: "basket strainer",
  },
  homeBS27: {
    image: require("./BS/Basket-Type-Strainer-25.jpg"),
    alt: "basket strainer",
  },
  homeBS28: {
    image: require("./BS/Basket-Type-Strainer-26.jpg"),
    alt: "basket strainer",
  },
  homeBS29: {
    image: require("./BS/Basket-Type-Strainer-27.jpg"),
    alt: "basket strainer",
  },
  homeBS30: {
    image: require("./BS/Basket-Type-Strainer-28.jpg"),
    alt: "basket strainer",
  },
  homeBS31: {
    image: require("./BS/Basket-Type-Strainer-29.jpg"),
    alt: "basket strainer",
  },
  homeBS32: {
    image: require("./BS/Basket-Type-Strainer-30.jpg"),
    alt: "basket strainer",
  },
  homeBS33: {
    image: require("./BS/Basket-Type-Strainer-31.jpg"),
    alt: "basket strainer",
  },
  homeBS34: {
    image: require("./BS/Basket-Type-Strainer-32.jpg"),
    alt: "basket strainer",
  },
  homeBS35: {
    image: require("./BS/Basket-Type-Strainer-33.jpg"),
    alt: "basket strainer",
  },
  homeBS36: {
    image: require("./BS/Basket-Type-Strainer-34.jpg"),
    alt: "basket strainer",
  },
  homeBS37: {
    image: require("./BS/Basket-Type-Strainer-35.jpg"),
    alt: "basket strainer",
  },

  // Y Type Strainers images
  // Y Type Strainers images
  // Y Type Strainers images

  homeYT1: {
    image: require("./YT/Y-Type-Strainer-0.1.jpg"),
    alt: "Y Type strainer",
  },
  homeYT2: {
    image: require("./YT/Y-Type-Strainer-0.2.jpg"),
    alt: "Y Type strainer",
  },
  homeYT3: {
    image: require("./YT/Y-Type-Strainer-1.jpg"),
    alt: "Y Type strainer",
  },
  homeYT4: {
    image: require("./YT/Y-Type-Strainer-2.1.jpg"),
    alt: "Y Type strainer",
  },
  homeYT5: {
    image: require("./YT/Y-Type-Strainer-2.2.jpg"),
    alt: "Y Type strainer",
  },
  homeYT6: {
    image: require("./YT/Y-Type-Strainer-2.jpg"),
    alt: "Y Type strainer",
  },
  homeYT7: {
    image: require("./YT/Y-Type-Strainer-3.jpg"),
    alt: "Y Type strainer",
  },
  homeYT8: {
    image: require("./YT/Y-Type-Strainer-4.jpg"),
    alt: "Y Type strainer",
  },
  homeYT9: {
    image: require("./YT/Y-Type-Strainer-5.jpg"),
    alt: "Y Type strainer",
  },
  homeYT10: {
    image: require("./YT/Y-Type-Strainer-6.jpg"),
    alt: "Y Type strainer",
  },
  homeYT11: {
    image: require("./YT/Y-Type-Strainer-7.jpg"),
    alt: "Y Type strainer",
  },
  homeYT12: {
    image: require("./YT/Y-Type-Strainer-8.jpg"),
    alt: "Y Type strainer",
  },
  homeYT13: {
    image: require("./YT/Y-Type-Strainer-9.jpg"),
    alt: "Y Type strainer",
  },
  homeYT14: {
    image: require("./YT/Y-Type-Strainer-10.jpg"),
    alt: "Y Type strainer",
  },
  homeYT15: {
    image: require("./YT/Y-Type-Strainer-11.jpg"),
    alt: "Y Type strainer",
  },
  homeYT16: {
    image: require("./YT/Y-Type-Strainer-12.jpg"),
    alt: "Y Type strainer",
  },
  homeYT17: {
    image: require("./YT/Y-Type-Strainer-13.jpg"),
    alt: "Y Type strainer",
  },
  homeYT18: {
    image: require("./YT/Y-Type-Strainer-14.jpg"),
    alt: "Y Type strainer",
  },
  homeYT19: {
    image: require("./YT/Y-Type-Strainer-15.jpg"),
    alt: "Y Type strainer",
  },
  homeYT20: {
    image: require("./YT/Y-Type-Strainer-16.jpg"),
    alt: "Y Type strainer",
  },
  homeYT21: {
    image: require("./YT/Y-Type-Strainer-17.jpg"),
    alt: "Y Type strainer",
  },
  homeYT22: {
    image: require("./YT/Y-Type-Strainer-18.jpg"),
    alt: "Y Type strainer",
  },
  homeYT23: {
    image: require("./YT/Y-Type-Strainer-19.jpg"),
    alt: "Y Type strainer",
  },
  homeYT24: {
    image: require("./YT/Y-Type-Strainer-20.jpg"),
    alt: "Y Type strainer",
  },
  homeYT25: {
    image: require("./YT/Y-Type-Strainer-21.jpg"),
    alt: "Y Type strainer",
  },
  homeYT26: {
    image: require("./YT/Y-Type-Strainer-22.jpg"),
    alt: "Y Type strainer",
  },
  homeYT27: {
    image: require("./YT/Y-Type-Strainer-23.jpg"),
    alt: "Y Type strainer",
  },
  homeYT28: {
    image: require("./YT/Y-Type-Strainer-24.jpg"),
    alt: "Y Type strainer",
  },
  homeYT29: {
    image: require("./YT/Y-Type-Strainer-25.jpg"),
    alt: "Y Type strainer",
  },

  // Filter gallery images
  // Filter gallery images
  // Filter gallery images
  homeDS1: {
    image: require("./DS/Duplex-Strainer-1.jpg"),
    alt: "Y Type strainer",
  },
  homeDS2: {
    image: require("./DS/Duplex-Strainer-2.jpg"),
    alt: "Y Type strainer",
  },
  homeDS3: {
    image: require("./DS/Duplex-Strainer-3.jpg"),
    alt: "Y Type strainer",
  },
  homeDS4: {
    image: require("./DS/Duplex-Strainer-4.jpg"),
    alt: "Y Type strainer",
  },
  homeDS5: {
    image: require("./DS/Duplex-Strainer-5.jpg"),
    alt: "Y Type strainer",
  },
  homeDS6: {
    image: require("./DS/Duplex-Strainer-6.jpg"),
    alt: "Y Type strainer",
  },
  homeDS7: {
    image: require("./DS/Duplex-Strainer-7.jpg"),
    alt: "Y Type strainer",
  },
  homeDS8: {
    image: require("./DS/Duplex-Strainer-8.jpg"),
    alt: "Y Type strainer",
  },
  homeDS9: {
    image: require("./DS/Duplex-Strainer-9.jpg"),
    alt: "Y Type strainer",
  },
  homeDS10: {
    image: require("./DS/Duplex-Strainer-10.jpg"),
    alt: "Y Type strainer",
  },
  homeDS11: {
    image: require("./DS/Duplex-Strainer-11.jpg"),
    alt: "Y Type strainer",
  },
  homeDS12: {
    image: require("./DS/Duplex-Strainer-12.jpg"),
    alt: "Y Type strainer",
  },
  homeDS13: {
    image: require("./DS/Duplex-Strainer-13.jpg"),
    alt: "Y Type strainer",
  },
  homeDS14: {
    image: require("./DS/Duplex-Strainer-14.jpg"),
    alt: "Y Type strainer",
  },
  homeDS15: {
    image: require("./DS/Duplex-Strainer-15.jpg"),
    alt: "Y Type strainer",
  },

  // Filter gallery images
  // Filter gallery images
  // Filter gallery images
  homeFilter1: {
    image: require("./Filters/Filter-Element-1.jpg"),
    alt: "Y Type strainer",
  },
  homeFilter2: {
    image: require("./Filters/Filter-Element-2.jpg"),
    alt: "Y Type strainer",
  },
  homeFilter3: {
    image: require("./Filters/Filter-Element-3.jpg"),
    alt: "Y Type strainer",
  },
  homeFilter4: {
    image: require("./Filters/Filter-Element-4.jpg"),
    alt: "Y Type strainer",
  },
  homeFilter5: {
    image: require("./Filters/Filter-Element-5.jpg"),
    alt: "Y Type strainer",
  },
  homeFilter6: {
    image: require("./Filters/Conical-Strainer-6.jpg"),
    alt: "Y Type strainer",
  },
  homeFilter7: {
    image: require("./Filters/Conical-Strainer-7.jpg"),
    alt: "Y Type strainer",
  },
  homeFilter8: {
    image: require("./Filters/Conical-Strainer-8.jpg"),
    alt: "Y Type strainer",
  },
  homeFilter9: {
    image: require("./Filters/Conical-Strainer-9.jpg"),
    alt: "Y Type strainer",
  },
  homeFilter10: {
    image: require("./Filters/Filter-Element-10.jpg"),
    alt: "Y Type strainer",
  },
  homeFilter11: {
    image: require("./Filters/Filter-Element-11.jpg"),
    alt: "Y Type strainer",
  },
  homeFilter12: {
    image: require("./Filters/Filter-Element-12.jpg"),
    alt: "Y Type strainer",
  },
  homeFilter13: {
    image: require("./Filters/Filter-Element-13.jpg"),
    alt: "Y Type strainer",
  },
  homeFilter14: {
    image: require("./Filters/Filter-Element-14.jpg"),
    alt: "Y Type strainer",
  },
  homeFilter15: {
    image: require("./Filters/Filter-Element-15.jpg"),
    alt: "Y Type strainer",
  },
  homeFilter16: {
    image: require("./Filters/Filter-Element-16.jpg"),
    alt: "Y Type strainer",
  },
  homeFilter17: {
    image: require("./Filters/Filter-Element-17.jpg"),
    alt: "Y Type strainer",
  },
  homeFilter18: {
    image: require("./Filters/Filter-Element-18.jpg"),
    alt: "Y Type strainer",
  },

  // POT gallery images
  // POT gallery images
  // POT gallery images
  homePot1: {
    image: require("./POT/pot-1.jpg"),
    alt: "Y Type strainer",
  },
  homePot2: {
    image: require("./POT/pot-2.jpg"),
    alt: "Y Type strainer",
  },
  homePot3: {
    image: require("./POT/pot-3.jpg"),
    alt: "Y Type strainer",
  },
  homePot4: {
    image: require("./POT/pot-4.jpg"),
    alt: "Y Type strainer",
  },

  //Old images
  project_thumbnail: {
    image: "https://via.placeholder.com/490x620/333/333",
    alt: "project thumbnail",
  },
  project_image: {
    image: "https://via.placeholder.com/726x1016/333/333",
    alt: "project image",
  },
  project_featured_image: {
    image: "https://via.placeholder.com/1078x825/333/333",
    alt: "project featured image",
  },
  project_gallery_1: {
    image: "https://via.placeholder.com/1078x825/333/333",
    alt: "project gallery 1",
  },
  project_gallery_2: {
    image: "https://via.placeholder.com/1078x825/333/333",
    alt: "project gallery 2",
  },
  project_amenities_1: {
    image: "https://via.placeholder.com/1078x825/333/333",
    alt: "project amenities 1",
  },
  project_amenities_2: {
    image: "https://via.placeholder.com/1078x825/333/333",
    alt: "project amenities 2",
  },
  project_floor_1: {
    image: "https://via.placeholder.com/626x394/333/333",
    alt: "project floor 1",
  },
  project_floor_2: {
    image: "https://via.placeholder.com/626x394/333/333",
    alt: "project floor 2",
  },
  projectlistingplaceholder: {
    image: "https://via.placeholder.com/490x620/333/333",
    alt: "project listing placeholder",
  },
  floorplaceholder: {
    image: "https://via.placeholder.com/626x394/333/333",
    alt: "floor placeholder",
  },
  galleryplaceholder: {
    image: "https://via.placeholder.com/1074x825/333/333",
    alt: "gallery placeholder",
  },
};
