// routes name
export const homepage = "/";
export const productListingRoute = "/products/:categorySlug";
export const productinner = "/products/:categorySlug/:productSlug";
export const strainersURL = "strainers-or-industrial-strainers";
export const filtersURL = "industrial-filters";
export const selfcleaningURL = "scs";
export const madetoorderURL = "mto";
export const aboutUs = "/about-us";
export const privacypolicy = "/privacy-policy";
export const contactUs = "/contact-us";
export const termsURL = "/terms-and-conditions";
export const sitemapURL = "/sitemap";
export const pageNotFound = "/404";
export const facebookURL = "https://m.facebook.com/asrevalves";
export const linkedinURL = "https://in.linkedin.com/company/asre-engineering";
export const pinterestURL = "https://in.pinterest.com/asreeng/";
export const youtubeURL =
  "https://youtube.com/@asreengineering4487?si=d69SqSbHHGW8Hkfv";
export const xURL =
  "https://x.com/AsreEngineering?t=ODZueGk7R8mwHXjACqKyxw&s=09";

export const devLink = "https://www.asresoft.com/";
