import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import {
  aboutUs,
  contactUs,
  homepage,
  pageNotFound,
  productListingRoute,
  productinner,
  sitemapURL,
  termsURL,
} from "./helpers/constant-words";
import "./common.scss";
import Header from "./Components/Header/Header";
import Home from "./pages/Home/Home";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Footer from "./Components/Footer/Footer";
import ProductListing from "./pages/ProductListing/ProductListing";
import ProductInner from "./pages/ProductInner/ProductInner";
import ContactUs from "./pages/ContactUs/ContactUs";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import About from "./pages/About/About";
import Sitemap from "./pages/Sitemap/Sitemap";

function Routing() {
  const location = useLocation();
  return (
    <>
      <Header />
      <Routes location={location} key={location.pathname}>
        <Route path={homepage} element={<Home />} />
        <Route path={aboutUs} element={<About />} />
        <Route path={productListingRoute} element={<ProductListing />} />
        <Route path={productinner} element={<ProductInner />} />
        <Route path={contactUs} element={<ContactUs />} />
        <Route path={termsURL} element={<TermsAndConditions />} />
        <Route path={sitemapURL} element={<Sitemap />} />
        <Route path={pageNotFound} element={<PageNotFound />} />
        <Route path="*" element={<Navigate to={pageNotFound} />} />
      </Routes>
      <Footer />
    </>
  );
}

export default Routing;
