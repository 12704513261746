import React, { useEffect, useState } from "react";
import { Banner } from "../../Components/Banner/Banner";
import {
  aboutUs,
  contactUs,
  filtersURL,
  homepage,
  madetoorderURL,
  selfcleaningURL,
  strainersURL,
  termsURL,
} from "../../helpers/constant-words";
import { images } from "../../images";
import MetaDecorator from "../../Components/MetaDecorator/MetaDecorator";
import "./sitemap.scss";
import { findAllProducts, findCategoryBySlug } from "../../Data/productsData";
import { NavLink } from "react-router-dom";

const Sitemap = () => {
  const [strainers, setStrainers] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selfCleaning, setSelfCleaning] = useState([]);
  const [madeToOrder, setMadeToOrder] = useState([]);

  useEffect(() => {
    const strainerSlug = findCategoryBySlug(strainersURL);
    const filterSlug = findCategoryBySlug(filtersURL);
    const selfCleaningSlug = findCategoryBySlug(selfcleaningURL);
    const madeToOrderSlug = findCategoryBySlug(madetoorderURL);

    const strainersData = findAllProducts(strainerSlug?.slug);
    const filtersData = findAllProducts(filterSlug?.slug);
    const selfCleaningData = findAllProducts(selfCleaningSlug?.slug);
    const madeToOrderData = findAllProducts(madeToOrderSlug?.slug);
    setStrainers(strainersData);
    setFilters(filtersData);
    setSelfCleaning(selfCleaningData);
    setMadeToOrder(madeToOrderData);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        metaTitle="Sitemap | Asre Engineering"
        metaDesc="Sitemap | Asre Engineering"
        canonicalLink={window.location.href}
      />
      <Banner
        breadcrumbWord1="Home"
        link1={homepage}
        breadcrumbWord4="Sitemap"
        background={images.termsban.image}
        bgSize="contain"
        disabled
      />
      <section className="sitemap_sec1">
        <div className="my_container">
          <div className="text_container">
            <h1 className="title text-center">Sitemap</h1>
            <div className="row">
              <div className="col-md-4">
                <p className="description">
                  <NavLink
                    to={`/products/${strainersURL}`}
                    activeclassname="link_selected"
                    className="route_link_black dropdown-item"
                  >
                    <strong>Strainers</strong>
                  </NavLink>
                </p>
                {strainers.map((item, i) => (
                  <p className="description" key={i}>
                    <NavLink
                      key={item.id}
                      to={`/products/${strainersURL}/${item.slug}`}
                      activeclassname="link_selected"
                      className="route_link_black dropdown-item"
                    >
                      {item.title}
                    </NavLink>
                  </p>
                ))}
              </div>
              <div className="col-md-4">
                <p className="description">
                  <NavLink
                    to={`/products/${strainersURL}`}
                    activeclassname="link_selected"
                    className="route_link_black dropdown-item"
                  >
                    <strong>Filters</strong>
                  </NavLink>
                </p>
                {filters.map((item, i) => (
                  <p className="description" key={i}>
                    <NavLink
                      key={item.id}
                      to={`/products/${strainersURL}/${item.slug}`}
                      activeclassname="link_selected"
                      className="route_link_black dropdown-item"
                    >
                      {item.title}
                    </NavLink>
                  </p>
                ))}
              </div>
              <div className="col-md-4">
                <p className="description">
                  <NavLink
                    to={homepage}
                    activeclassname="link_selected"
                    className="route_link_black dropdown-item"
                  >
                    <strong>Home</strong>
                  </NavLink>
                </p>
                {selfCleaning.map((item, i) => (
                  <p className="description" key={i}>
                    <NavLink
                      key={item.id}
                      to={`/products/${strainersURL}/${item.slug}`}
                      activeclassname="link_selected"
                      className="route_link_black dropdown-item"
                    >
                      <strong>{item.title}</strong>
                    </NavLink>
                  </p>
                ))}
                {madeToOrder.map((item, i) => (
                  <p className="description" key={i}>
                    <NavLink
                      key={item.id}
                      to={`/products/${strainersURL}/${item.slug}`}
                      activeclassname="link_selected"
                      className="route_link_black dropdown-item"
                    >
                      <strong>{item.title}</strong>
                    </NavLink>
                  </p>
                ))}
                <p className="description">
                  <NavLink
                    to={aboutUs}
                    activeclassname="link_selected"
                    className="route_link_black dropdown-item"
                  >
                    <strong>About</strong>
                  </NavLink>
                </p>
                <p className="description">
                  <NavLink
                    to={contactUs}
                    activeclassname="link_selected"
                    className="route_link_black dropdown-item"
                  >
                    <strong>Contact</strong>
                  </NavLink>
                </p>
                <p className="description">
                  <NavLink
                    to={termsURL}
                    activeclassname="link_selected"
                    className="route_link_black dropdown-item"
                  >
                    <strong>Terms and Conditions</strong>
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sitemap;
