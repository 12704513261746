import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper";
import {
  aboutArr,
  advantagessArr,
  banners,
  galleryData,
  ourservicesArr,
  secretData,
  segmentData,
} from "../../Data/HomepageData";
import "./home.scss";
import { findProductWhere as getFeaturedProjects } from "../../Data/productsData";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";
import ImageWithFallback from "../../Components/ImageWithFallback/ImageWithFallback";
import FsLightbox from "fslightbox-react";
import {
  aboutUs,
  contactUs,
  filtersURL,
  selfcleaningURL,
  strainersURL,
} from "../../helpers/constant-words";
import { images, rightarrowblue } from "../../images";

const Home = () => {
  const { width } = useWindowSize();
  const featuredProjects = getFeaturedProjects({ isFeatured: 1 });
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    sourceIndex: 0,
  });
  const galleryimages = galleryData.map(({ jpg }) => {
    return jpg;
  });

  function openLightboxOnSlide(index) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      sourceIndex: index,
    });
  }

  const bannerList = banners.map((data, i) => (
    <SwiperSlide>
      {/* <div className="overlay"></div> */}
      <ImageWithFallback
        src={width > 600 ? data.backimg : data.mobImg}
        fallbackSrc={width > 600 ? data.backimg : data.mobImg}
        alt={data.alt}
        className="hero_img"
        loading="eager"
        width={1920}
        height={1080}
      />
      <div className="text_container">
        {/* <div className="title">{data.title}</div> */}
        {/* <p className="desc">{data.desc}</p> */}
      </div>
    </SwiperSlide>
  ));

  const productList = featuredProjects?.map((item, i) => (
    <SwiperSlide key={i}>
      <Link to={`products/${item.parentSlug}/${item.slug}`}>
        <div className="featured_prod_box">
          <div className="imageanimate zoom-out">
            <ImageWithFallback
              className="product_img"
              loading="lazy"
              width={400}
              height={400}
              src={item.image}
              fallbackSrc={item.fallImage}
              alt={item.alt}
            />
          </div>
          <div className="text_container">
            {/* <div className="content_box">
              <h3 className="product_name">{item.title}</h3>
              <p
                className="product_desc"
                dangerouslySetInnerHTML={{
                  __html: reduceDescSize(item.description, 270).replace(
                    /(<? *script)/gi,
                    "illegalscript"
                  ),
                }}
              />
            </div> */}
            <div className="cta_wrapper">
              <button className="common_button">{item.title}</button>
            </div>
          </div>
        </div>
      </Link>
    </SwiperSlide>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="hero_banner">
        <Swiper
          modules={[Navigation, Autoplay, Pagination]}
          // navigation={true}
          pagination={{
            clickable: true,
          }}
          loop={true}
          grabCursor={true}
          allowTouchMove={true}
          speed={500}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        >
          {bannerList}
        </Swiper>
      </section>
      <section className="home_sec2">
        <div className="manu_wrap">
          <h1 className="manufacturing_title">
            Strainer Manufacturers & Suppliers in India
          </h1>
        </div>
        <div className="my_container">
          <div className="about_us_card">
            <div className="row about_row">
              <div className="col-md-4 p-0">
                <Swiper modules={[Autoplay]} loop={true} speed={1000}>
                  {aboutArr.map((item, i) => (
                    <SwiperSlide>
                      <ImageWithFallback
                        src={width > 600 ? item.img : item.imgMob}
                        fallbackSrc={
                          width > 600 ? item.fallback : item.fallbackMob
                        }
                        alt={item.alt}
                        className="about_img img-fluid"
                        width={400}
                        height={600}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="col-md-8 p-0">
                <div className="text_container">
                  {/* <h2 className="title">
                    Industrial Strainer Manufacturer in Mumbai India
                  </h2> */}
                  <p className="description">
                    We are leading Strainer manufacturers in India. We offer a
                    wide range of products including{" "}
                    <Link
                      to={`/products/${strainersURL}/basket-type-strainer`}
                      className="hyperlink"
                    >
                      Basket Type Strainer
                    </Link>
                    ,{" "}
                    <Link
                      to={`/products/${strainersURL}/basket-type-strainer`}
                      className="hyperlink"
                    >
                      Bucket Type Strainer
                    </Link>
                    ,{" "}
                    <Link
                      to={`/products/${strainersURL}/y-type-strainer`}
                      className="hyperlink"
                    >
                      Y Strainer
                    </Link>
                    ,{" "}
                    <Link
                      to={`/products/${strainersURL}/t-type-strainer`}
                      className="hyperlink"
                    >
                      T type Strainer
                    </Link>
                    ,{" "}
                    <Link
                      to={`/products/${strainersURL}/duplex-type-strainer`}
                      className="hyperlink"
                    >
                      Duplex Strainer
                    </Link>
                    ,{" "}
                    <Link
                      to={`/products/${strainersURL}/conical-temporery-strainer`}
                      className="hyperlink"
                    >
                      Conical Strainer
                    </Link>
                    ,{" "}
                    <Link
                      to={`/products/${strainersURL}/conical-temporery-strainer`}
                      className="hyperlink"
                    >
                      Temporary Strainer
                    </Link>
                    ,{" "}
                    <Link
                      to="https://www.asreengineering.com/temp-strainers/"
                      className="hyperlink"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Filter Element
                    </Link>
                    ,{" "}
                    <Link
                      to={`/products/${filtersURL}/bag-filter`}
                      className="hyperlink"
                    >
                      Bag Filter
                    </Link>
                    ,{" "}
                    <Link
                      to={`/products/${strainersURL}/magnetic-strainer`}
                      className="hyperlink"
                    >
                      Magnetic Filter
                    </Link>
                    ,{" "}
                    <Link
                      to={`/products/${selfcleaningURL}/self-cleaning-strainer`}
                      className="hyperlink"
                    >
                      Automatic Strainers
                    </Link>{" "}
                    etc. The ASRE ENGINEERING Has 25+ Years of Experience in
                    Industrial Strainers. <br />
                    <br />
                    <Link
                      to="https://www.asreengineering.com/"
                      className="hyperlink"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ASRE ENGINEERING
                    </Link>{" "}
                    is known for its efficiency, effectiveness and reliability
                    and is backed by our commitment to strive for excellence and
                    dedication to customer service.Our industrial strainers are
                    designed to offer maximum reliability and are of rugged
                    construction for long life. These strainers manufactured by
                    us are very cost-effective and reliable.The company uses
                    only premium quality for manufacturing them which ultimately
                    them provides, them the superior quality and besides this
                    they are designed as per the international quality standards
                    which also promotes their global usage.
                    <br />
                    <br />
                    At Asre Engineering, we take great pride in our commitment
                    to excellence. The entire range of our{" "}
                    <b>industrial strainers</b> and their elements is
                    meticulously designed in accordance with the latest industry
                    standards. We leave no room for compromise when it comes to
                    using the best quality raw materials, ensuring that the
                    final products boast superior quality. Our company has been
                    manufacturing and supplying these strainers for more than 25
                    years, and our satisfied customers are our main concern.
                  </p>
                  <div className="cta_wrapper">
                    <Link to={aboutUs}>
                      <button className="common_button">About Us</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home_sec3">
        <div className="my_container">
          <div className="text_container">
            <h3 className="title">Our Products</h3>
            {/* <p className="description">
              Discover the essence of innovation in our mechanical products
              gallery. Feast your eyes on precision-engineered wonders that
              redefine industry standards.
            </p> */}
          </div>
          <div className="featured_products_wrapper">
            <Swiper
              modules={[Navigation, Autoplay]}
              loop={true}
              autoHeight={false}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                300: {
                  slidesPerView: 1.1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2.25,
                  spaceBetween: 30,
                },
                993: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
            >
              {productList}
            </Swiper>
          </div>
          <div className="text_container">
            <h3 className="title">Our Speciality</h3>
            <p className="description list_desc">
              <ul>
                {ourservicesArr.map((point) => (
                  <li>{point}</li>
                ))}
              </ul>
            </p>
          </div>
          <div className="text_container">
            <h3 className="title">Our Services</h3>
            <p className="description list_desc2">
              We are the leading <b>Industrial Strainers manufacturer</b>,
              supplier, and exporter in India.our headquarters are located in
              Mumbai, one of the most well-developed cities in Maharashtra. Our
              manufacturing unit is equipped with the latest technology
              machinery and accessories, facilitating the production of
              premium-quality <b>Industrial Strainers</b> in various shapes and
              sizes to suit diverse needs.We take pride in providing the finest
              quality industrial strainers and their accessories, which are in
              high demand in both the national and international markets.Our
              entire range of strainers is renowned for its exceptional quality,
              and each product undergoes rigorous quality assurance testing
              before being supplied and exported worldwide.
            </p>
          </div>
          <div className="text_container">
            <h3 className="title">Advantages of Our Service</h3>
            <p className="description list_desc">
              <ul>
                {advantagessArr.map((advantage) => (
                  <li>{advantage}</li>
                ))}
              </ul>
            </p>
          </div>
        </div>
      </section>
      <section className="home_sec4">
        <div className="my_container">
          <div className="text_container">
            <h3 className="title">The Key to Our Excellence</h3>
            <p className="description">
              Over The Years, We have dedicated significant efforts to planning
              and development, aiming to create the finest products for both the
              national and international markets. As a result of our commitment
              we have successfully established ourselves as{" "}
              <b>ASRE ENGINEERING</b>, a brand synonymous with Excellence,
              Quality and Reliability.
            </p>
            {width < 993 ? (
              <div className="cta_wrapper">
                <Link to={contactUs}>
                  <button className="common_button">Get a Quote</button>
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="secret_grid">
            <div className="row">
              {secretData.map((data, i) => (
                <div className="col" key={i}>
                  <div className="secret_box">
                    <img
                      width={80}
                      height={80}
                      src={data.img}
                      alt={data.alt}
                      className="secret_img"
                      loading="lazy"
                    />
                    <div className="text_container">
                      <h3 className="title">{data.title}</h3>
                      <p className="description">{data.desc}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {width > 992 ? (
            <div className="cta_wrapper">
              <Link to={contactUs}>
                <button className="common_button">Get a Quote</button>
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
      <section className="home_sec5">
        <div className="my_container">
          <div className="row">
            <div className="col-lg-4 p-0">
              <div className="text_container">
                <h4 className="subtitle">→ Major Industries we Serve</h4>
                <h5 className="title">Strainers , Filters and Accessories</h5>
                <p className="desc">
                  ASRE ENGINEERING has been Regularly Supplying best Filtration
                  strainers and Filters To the Different Industrial Markets.
                </p>
              </div>
            </div>
            <div className="col-lg-8 p-0">
              <div className="segment_wrapper">
                <ul className="segement_list">
                  {segmentData.map((segement, i) => (
                    <li className="segment_box">
                      <img
                        src={segement.img}
                        alt={segement.alt}
                        className="segment_img"
                        loading="lazy"
                      />
                      <div className="title">{segement.title}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home_sec6">
        <div className="my_container">
          <div className="text_container">
            <h3 className="title">Our Gallery</h3>
            {/* <p className="description">
              Discover the essence of innovation in our mechanical products
              gallery. Feast your eyes on precision-engineered wonders that
              redefine industry standards.
            </p> */}
          </div>
          {/* {width > 600 ? (
            <div className="row">
              {galleryData.map((image, i) => (
                <div className="col-md-3" key={i}>
                  <ImageWithFallback
                    width={600}
                    height={600}
                    src={image.webp}
                    fallbackSrc={image.jpg}
                    alt={image.alt}
                    className="gallery_img"
                    onClick={() => openLightboxOnSlide(i)}
                  />
                </div>
              ))}
            </div>
          ) : ( */}
          <Swiper
            modules={[Autoplay, Navigation]}
            slidesPerView={4}
            spaceBetween={20}
            navigation={{
              nextEl: ".gallery-button-next",
              prevEl: ".gallery-button-prev",
            }}
            autoplay={{
              delay: 4500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1.2,
              },
              600: {
                slidesPerView: 2.2,
              },
              992: {
                slidesPerView: 4,
              },
            }}
          >
            {galleryData.map((image, i) => (
              <SwiperSlide key={i}>
                <ImageWithFallback
                  width={600}
                  height={600}
                  src={image.webp}
                  fallbackSrc={image.jpg}
                  alt={image.alt}
                  className="gallery_img"
                  onClick={() => openLightboxOnSlide(i)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          {/* )} */}
          <div className="gallery_arrows_wrapper">
            <img
              width={94}
              height={94}
              src={images.leftarrowblue.image}
              alt="left arrow"
              loading="lazy"
              className="left_arrow gallery-button-prev"
            />
            <img
              width={94}
              height={94}
              src={rightarrowblue}
              alt="right arrow"
              loading="lazy"
              className="right_arrow gallery-button-next"
            />
          </div>
        </div>
      </section>

      <FsLightbox
        toggler={lightboxController.toggler}
        sources={galleryimages}
        sourceIndex={lightboxController.sourceIndex}
      />
    </>
  );
};

export default Home;
