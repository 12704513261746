import React, { useEffect } from "react";
import { Banner } from "../../Components/Banner/Banner";
import { homepage } from "../../helpers/constant-words";
import { images } from "../../images";
import MetaDecorator from "../../Components/MetaDecorator/MetaDecorator";
import "./termsandconditions.scss";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        metaTitle="Terms and Conditions | Asre Engineering"
        metaDesc="Terms and Conditions | Asre Engineering"
        canonicalLink={window.location.href}
      />
      <Banner
        breadcrumbWord1="Home"
        link1={homepage}
        breadcrumbWord4="Terms and Conditions"
        background={images.termsban.image}
        bgSize="contain"
        disabled
      />
      <section className="terms_sec1">
        <div className="my_container">
          <div className="text_container">
            <h1 className="title text-center">Terms and Conditions</h1>
            <p className="description">
              Welcome to our website. We, ASRE ENGINEERING maintain this website
              for informational, general promotional, public information and
              investor relations purposes. The following Terms and Conditions of
              Use ("Terms and Conditions") govern your use of our Site and the
              services offered on this site.
            </p>
            <h2 className="title">Terms of use</h2>
            <p className="description">
              By accessing or using the website, you agree to the Terms and
              Conditions, as well as any additional rules & guidelines that we
              may post on the site. We may make changes to this Terms and
              Conditions at times without notifying you; we suggest that you
              review the Terms of use each time you access our website.
            </p>
            <h3 className="title">Copyright</h3>
            <p className="description">
              The entire content included in this website, including but not
              limited to text, site design, graphics, logos, code, software,
              icons and images and all other materials associated with or within
              this site, shall remain the sole property of ASRE ENGINEERING
              including all associated copyrights and other intellectual
              property rights. All rights reserved.
            </p>
            <h4 className="title">Disclaimers</h4>
            <p className="description">
              In preparing this website, we have tried to assure that the
              information posted is accurate, complete and current as of the
              dates indicated on the screens and that your use of the website
              will be uninterrupted and error-free. However, we make no
              guarantees or warranties of any kind, express or implied,
              regarding the content or operation of the site. You access and use
              this site at your own risk. The content (including all information
              available on or through the site) is provided "as is" and we are
              not responsible for its use or misuse. Access to this site from
              locations where the content may be illegal is prohibited and you
              are responsible for compliance with applicable local laws. ASRE ENGINEERING
              does not make any warrantees or representations regarding the use
              of the materials in the site in terms of their correctness,
              accuracy, adequacy, reliability or otherwise.
              <br />
              <br />
              WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE DISCLAIM, TO
              THE MAXIMUM EXTENT PERMITTED BY LAW, ANY AND ALL (I) WARRANTIES OF
              MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE (INCLUDING
              SUITABILITY OF THE SITE FOR YOUR NEEDS OR REQUIREMENTS OR THOSE OF
              ANY OTHER PERSON), (II) WARRANTIES AGAINST INFRINGEMENT OF ANY
              THIRD PARTY INTELLECTUAL PROPERTY OR PROPRIETARY RIGHTS, (III)
              WARRANTIES RELATING TO THE ACCURACY, RELIABILITY, CORRECTNESS OR
              COMPLETENESS OF THE CONTENT, (IV) WARRANTIES RELATING TO THE
              OPERATION OF THE SITE (INCLUDING DELAYS, INTERRUPTIONS, ERRORS,
              VIRUSES, DEFECTS OR OMISSIONS) AND (V) WARRANTIES OTHERWISE
              RELATING TO PERFORMANCE, NONPERFORMANCE OR OTHER ACTS OR OMISSIONS
              BY US OR ANY THIRD PARTY.
            </p>
            <h5 className="title">Third party links</h5>
            <p className="description">
              To provide you with increased value, we may include third party
              links on our site. These linked sites have separate and
              independent privacy policies of their own. Accordingly, we cannot
              be responsible for the privacy practices used by other website
              owners or the content, accuracy of these linked sites. We strongly
              advise you to review the Privacy Policy of every site you visit.
              And welcome any feedback about these linked site, including if a
              specific link does not work.
            </p>
            <h6 className="title">Limitation of liability</h6>
            <p className="description">
              ASRE ENGINEERING will not be liable for any special or consequential damages
              that result from the use of, or the inability to use, the
              materials on this site or the performance of the products, even if
              ASRE ENGINEERING has been advised of the possibility of such damages.
              Applicable law may not allow the limitation of exclusion of
              liability or incidental or consequential damages, so the above
              limitation or exclusion may not apply to you.
            </p>
            <h6 className="title">Indemnity</h6>
            <p className="description">
              By using the website, you agree to indemnify, defend and hold
              ASRE ENGINEERING harmless from and against all claims, losses, liability,
              cost and expenses resulting from your violation of these Terms and
              Conditions or use or misuse of this site, or from the use of any
              content or any service, product, information or materials provided
              through this site. Your obligations under this section will
              survive your termination of access to or use of this website.
            </p>
            <h6 className="title">Termination</h6>
            <p className="description">
              ASRE ENGINEERING may, in its sole discretion, terminate your use
              of the site or your rights under this terms and conditions without
              notice at any time, for any reason. ASRE ENGINEERING may also discontinue
              providing the site or any one or part, with or without notice.
              ASRE ENGINEERING shall not be liable to you or any third party for any of the
              foregoing. The provisions relating to Copyrights, Disclaimer,
              Limitation of Liability and Indemnification, shall survive any
              termination.
            </p>
            <h6 className="title">Notice</h6>
            <p className="description">
              ASRE ENGINEERING may deliver notice to you by means of e-mail, a
              general notice on the site, or by other reliable method to the
              address you have provided to ASRE ENGINEERING
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditions;
