import React, { useState, useLayoutEffect, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./header.scss";
import {
  logo,
  downarroww,
  downarrowb,
  calliconblack,
  callblack,
  mailblack,
  logowhite,
  emailiconwhite,
} from "../../images";
import MobileMenu from "../MobileMenu/MobileMenu";
import { useWindowSize } from "react-use";
import {
  aboutUs,
  contactUs,
  filtersURL,
  homepage,
  madetoorderURL,
  selfcleaningURL,
  strainersURL,
} from "../../helpers/constant-words";
import {
  findAllProducts,
  findCategoryBySlug,
  productsArr,
} from "../../Data/productsData";

const body = document.querySelector("body");

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const { width: windowWidth } = useWindowSize();
  const [menuItemActiveIndex, setMenuItemActiveIndex] = useState(null);
  const sticky = useStickyHeader(0);
  const headerClasses = `header ${sticky ? "navbar_bg" : ""}`;
  const [strainers, setStrainers] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selfCleaning, setSelfCleaning] = useState([]);
  const [madeToOrder, setMadeToOrder] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    setOpenMenu(false);
  }, [pathname]);

  useEffect(() => {
    const strainerSlug = findCategoryBySlug(strainersURL);
    const filterSlug = findCategoryBySlug(filtersURL);
    const selfCleaningSlug = findCategoryBySlug(selfcleaningURL);
    const madeToOrderSlug = findCategoryBySlug(madetoorderURL);

    const strainersData = findAllProducts(strainerSlug?.slug);
    const filtersData = findAllProducts(filterSlug?.slug);
    const selfCleaningData = findAllProducts(selfCleaningSlug?.slug);
    const madeToOrderData = findAllProducts(madeToOrderSlug?.slug);
    setStrainers(strainersData);
    setFilters(filtersData);
    setSelfCleaning(selfCleaningData);
    setMadeToOrder(madeToOrderData);
  }, []);

  function useStickyHeader(offset = 0) {
    const [stick, setStick] = useState(false);

    const handleScroll = () => {
      setStick(window.scrollY > offset);
    };

    useLayoutEffect(() => {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    });

    return stick;
  }

  const handleClick = () => {
    if (!openMenu) {
      // Disable scroll
      // body.style.overflow = "hidden";
      setOpenMenu(true);
    } else {
      // Enable scroll
      // body.style.overflow = "auto";
      setOpenMenu(false);
    }
  };

  const handlePlusClick = (i) => {
    setMenuItemActiveIndex(i);
  };

  const mbMenuArr = [
    {
      linkName: "Strainers",
      subMenu: [
        {
          key: 1,
          redirectLink: `products/${strainersURL}/${productsArr[0].slug}`,
          subLinkName: productsArr[0].title,
        },
        {
          key: 2,
          redirectLink: `products/${strainersURL}/${productsArr[1].slug}`,
          subLinkName: productsArr[1].title,
        },
        {
          key: 3,
          redirectLink: `products/${strainersURL}/${productsArr[2].slug}`,
          subLinkName: productsArr[2].title,
        },
        {
          key: 4,
          redirectLink: `products/${strainersURL}/${productsArr[3].slug}`,
          subLinkName: productsArr[3].title,
        },
        {
          key: 5,
          redirectLink: `products/${strainersURL}/${productsArr[4].slug}`,
          subLinkName: productsArr[4].title,
        },
        {
          key: 6,
          redirectLink: `products/${strainersURL}/${productsArr[5].slug}`,
          subLinkName: productsArr[5].title,
        },
        {
          key: 7,
          redirectLink: `products/${strainersURL}/${productsArr[6].slug}`,
          subLinkName: productsArr[6].title,
        },
      ],
    },
    {
      linkName: "Filters",
      subMenu: [
        {
          key: 1,
          redirectLink: `products/${filtersURL}/${productsArr[7].slug}`,
          subLinkName: productsArr[7].title,
        },
        {
          key: 2,
          redirectLink: `products/${filtersURL}/${productsArr[8].slug}`,
          subLinkName: productsArr[8].title,
        },
        {
          key: 3,
          redirectLink: `products/${filtersURL}/${productsArr[9].slug}`,
          subLinkName: productsArr[9].title,
        },
      ],
    },
    {
      isLink: true,
      redirectLink: `products/${selfcleaningURL}/${selfCleaning[0]?.slug}`,
      linkName: "Self Cleaning Strainers",
    },
    {
      isLink: true,
      redirectLink: `products/${madetoorderURL}/${madeToOrder[0]?.slug}`,
      linkName: "Made to Order",
    },
    {
      isLink: true,
      redirectLink: contactUs,
      linkName: "Contact",
    },
  ];

  const handlelogoClick = () => {
    window.scrollTo(0, 0);
  };

  const mbMenuList = mbMenuArr
    ? mbMenuArr.map((mbSingleMenu, i) => (
        <React.Fragment key={i}>
          <MobileMenu
            mbSingleMenu={mbSingleMenu}
            menuItemIndex={i + 1}
            handlePlusClick={handlePlusClick}
            menuItemActiveIndex={menuItemActiveIndex}
            setOpenMenu={openMenu}
          />
        </React.Fragment>
      ))
    : null;

  return (
    <>
      <header
        // className={`header ${isBg ? "navbar_bg" : ""}`}
        className={headerClasses}
      >
        <div className="ticker_wrap">
          <div className="my_container">
            <div className="ticker">
              <p className="title">
                Design, Development , Manufacturer, Exporter and Supplier Of
                Industrial Strainers and Filters.
              </p>
              <div className="contact_email_wrap">
                <img
                  className="call_icon_anime"
                  src={calliconblack}
                  alt="call icon"
                  loading="lazy"
                />
                <a href="tel:+917208148600" className="contact_no">
                  +91 7208148600
                </a>
                <img
                  className="call_icon_anime mail_icon"
                  src={emailiconwhite}
                  alt="call icon"
                  loading="lazy"
                />
                <a href="mailto:mail@asreengineering.com" className="email">
                  mail@asreengineering.com
                </a>
              </div>
            </div>
          </div>
        </div>
        {windowWidth > 992 ? (
          <div className="my_container">
            <div className="Navbarclass">
              <NavLink to={homepage}>
                <div className="logo">
                  <img
                    src={`${sticky ? logo : logowhite}`}
                    className="nav_logo_img"
                    alt="asre logo img"
                    onClick={handlelogoClick}
                    loading="lazy"
                  />
                </div>
              </NavLink>
              <ul>
                <li className="nav-item dropdown">
                  <Link
                    to={`products/${strainersURL}`}
                    className="nav-link"
                    // aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Strainers
                    <img
                      className="down_arrow"
                      src={`${sticky ? downarrowb : downarroww}`}
                      alt="down arrow"
                      loading="lazy"
                    />
                  </Link>
                  <div className="dropdown-menu">
                    {strainers.map((item, i) => (
                      <div>
                        <NavLink
                          key={item.id}
                          to={`products/${strainersURL}/${item.slug}`}
                          activeclassname="link_selected"
                          className="route_link_black dropdown-item"
                        >
                          {item.title}
                        </NavLink>
                      </div>
                    ))}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to={`products/${filtersURL}`}
                    className="nav-link"
                    // aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Filters
                    <img
                      className="down_arrow"
                      src={`${sticky ? downarrowb : downarroww}`}
                      alt="down arrow"
                      loading="lazy"
                    />
                  </Link>
                  <div className="dropdown-menu">
                    {filters.map((item, i) => (
                      <div>
                        <NavLink
                          key={item.id}
                          to={`products/${filtersURL}/${item.slug}`}
                          activeclassname="link_selected"
                          className="route_link_black dropdown-item"
                        >
                          {item.title}
                        </NavLink>
                      </div>
                    ))}
                  </div>
                </li>
                <li>
                  <NavLink
                    to={`products/${selfcleaningURL}/${selfCleaning[0]?.slug}`}
                    activeclassname="link_selected"
                    className="route_link"
                    exact="true"
                  >
                    Self Cleaning Strainer
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`products/${madetoorderURL}/${madeToOrder[0]?.slug}`}
                    activeclassname="link_selected"
                    className="route_link"
                    exact="true"
                  >
                    Made To Order
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={aboutUs}
                    activeclassname="link_selected"
                    className="route_link"
                    exact="true"
                  >
                    About
                  </NavLink>
                </li>
                <li className="contact_btn">
                  <NavLink
                    to={contactUs}
                    activeclassname="link_selected"
                    className="route_link"
                    exact="true"
                  >
                    Contact
                  </NavLink>
                </li>
                {/* <li>
                  <a
                    href="https://wa.me/+"
                    activeclassname="link_selected"
                    exact="true"
                  >
                    <img src={whatsapp} alt="whatsapp icon" loading="lazy" />
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        ) : (
          <>
            <div className="my_container">
              <div className="mb_nav_flex">
                <NavLink to={homepage}>
                  {sticky === false && openMenu === false && (
                    <img
                      src={logowhite}
                      alt="logo mobile"
                      loading="lazy"
                      className="mb_nav_logo_img"
                      onClick={handlelogoClick}
                    />
                  )}
                  {openMenu === true && (
                    <img
                      src={logo}
                      alt="logo mobile"
                      loading="lazy"
                      className="mb_nav_logo_img"
                      onClick={handlelogoClick}
                    />
                  )}
                  {sticky === true && openMenu === false && (
                    <img
                      src={logo}
                      alt="logo mobile"
                      loading="lazy"
                      className="mb_nav_logo_img"
                      onClick={handlelogoClick}
                    />
                  )}
                </NavLink>

                <div
                  className={`nav_icon ${
                    openMenu ? "open_nav" : "" || sticky ? "" : "nav_icon_white"
                  } `}
                  onClick={handleClick}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div className={`mb_menu ${openMenu ? "mb_menu_active" : ""}`}>
                <div className="mb_links_wrapper_cont">{mbMenuList}</div>

                <div className="header-socialmedia-icon">
                  <div className="title">
                    Design, Development , Manufacturer, Exporter and Supplier Of
                    Industrial Strainers and Filters.
                  </div>
                  <div>
                    <img
                      src={callblack}
                      loading="lazy"
                      alt="call"
                      className="header-call-icon"
                    ></img>
                    <a href="tel:+917208148600" className="header-contact">
                      +91 7208148600
                    </a>
                    <div>
                      <img
                        src={mailblack}
                        loading="lazy"
                        alt="mail"
                        className="header-mail-icon"
                      ></img>
                      <a
                        href="mailto:mail@asreengineering.com"
                        className="header-mail"
                      >
                        mail@asreengineering.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </header>
    </>
  );
};

export default Header;
