import React, { useEffect } from "react";
import "./about.scss";
import MetaDecorator from "../../Components/MetaDecorator/MetaDecorator";
import { images } from "../../images";
import ImageWithFallback from "../../Components/ImageWithFallback/ImageWithFallback";
import { whyChooseData } from "../../Data/HomepageData";
import { useWindowSize } from "react-use";
import { Banner } from "../../Components/Banner/Banner";
import { homepage } from "../../helpers/constant-words";

const About = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        metaTitle="Industrial Strainers manufacturer - Asre Engineering"
        metaDesc="Industrial Strainers manufacturer - Asre Engineering"
        canonicalLink={window.location.href}
      />
      <Banner
        breadcrumbWord1="Home"
        link1={homepage}
        breadcrumbWord4="About Us"
        disabled
      />
      <section className="about_sec1">
        <div className="my_container">
          <div className="about_us_card">
            <div className="row about_row">
              <div className="col-md-4 p-0 order-md-2">
                <ImageWithFallback
                  src={
                    width > 600
                      ? images.aboutHero.image
                      : images.aboutHeroMob.image
                  }
                  fallbackSrc={
                    width > 600
                      ? images.aboutHeroJpg.image
                      : images.aboutHeroJpgMob.image
                  }
                  alt={images.aboutHero.alt}
                  className="about_img img-fluid"
                  width={400}
                  height={600}
                />
              </div>
              <div className="col-md-8 p-0 order-md-1">
                <div className="text_container">
                  <h1 className="title">About Us</h1>
                  <p className="description">
                    Asre Engineering is a leading provider of premium quality
                    Industrial Strainers in India. With over 25+ of experience
                    in manufacturing and supplying a wide range of Industrial
                    Strainers and Filters, we have established ourselves as a
                    reliable name in the industry. <br />
                    <br />
                    Our product range includes various types of strainers such
                    as Basket Type Strainer, Bucket Type Strainer, T Type
                    Strainer, Y Type Strainer, Bag Type Strainer, Conical Type
                    Strainer, and more. These strainers are supplied and
                    exported worldwide, and our primary focus is to offer the
                    best solutions to our customers at user-friendly prices.
                    <br />
                    <br />
                    Our manufacturing unit is located in Mumbai, Maharashtra,
                    strategically positioned for easy accessibility via roadway,
                    railway, and airway, enabling us to deliver products
                    promptly to customers, regardless of their location.
                    <br />
                    <br />
                    We boast modern technology facilities that promote the
                    production of top-quality strainers. Our dedicated teams
                    work diligently to ensure excellence in all aspects of our
                    operations. The Research and Development team constantly
                    innovates new technologies and improves the quality of
                    existing products. The production team ensures the use of
                    the finest raw materials and adheres to desired
                    specifications. The quality assurance team rigorously tests
                    each product before supply and export, while the packaging
                    team ensures secure transportation.
                    <br />
                    <br />
                    Our staff plays a crucial role in providing the best
                    Industrial Strainers to our clients. Over the years, we have
                    served thousands of customers, ranging from medium to
                    large-scale industries. Our strainers find high demand in
                    sectors such as Petroleum, Chemical, Steel, Food, Mining,
                    Power Plants, Refineries, Cement, and more. Additionally, we
                    offer Industrial Strainers customization, tailoring products
                    to meet individual customer requirements. ASRE ENGINEERING
                    is have been serving the industry since 1999.
                  </p>
                  {/* <div className="cta_wrapper">
                    <button className="common_button">About Us</button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row mission_vision_row">
            <div className="col-md-6">
              <div className="image_wrap">
                <img
                  src={images.mission.image}
                  alt={images.mission.alt}
                  className="icon"
                  loading="lazy"
                />
              </div>
              <div className="text_container">
                <h2 className="title">Mission</h2>
                <p className="description">
                  Asre Engineering is a leading provider of premium quality
                  Industrial Strainers in India. With over 25+ of experience in
                  manufacturing and supplying a wide range of Industrial
                  Strainers and Filters, we have established ourselves as a
                  reliable name in the industry.
                  <br />
                  <br />
                  We are providing optimum customer service and satisfaction
                  with the help of our employees and associates by adopting an
                  innovative approach, positive attitude, teamwork, and advanced
                  technology.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="image_wrap">
                <img
                  src={images.Vision.image}
                  alt={images.Vision.alt}
                  className="icon"
                  loading="lazy"
                />
              </div>
              <div className="text_container">
                <h2 className="title">Vision & Philosophy</h2>
                <p className="description">
                  By providing world-class quality Industrial strainer products
                  as well as service and maintaining the highest level of
                  satisfaction to customers and become a frontrunner of
                  filtration business.
                  <br />
                  <br />
                  Continue to innovate and increase our investment in Research &
                  Development to give good quality products to our customers as
                  well as secure our future. We Asre Engineering acquire &
                  employ the latest technology & trends with the intent of
                  creating value for our customers, suppliers, and employees.
                </p>
              </div>
            </div>
          </div>
          <div className="our_team_wrap">
            <div className="team_box">
              <img
                src={images.Design.image}
                alt={images.Design.alt}
                className="icon"
                loading="lazy"
              />
              <h3 className="title">Design Team</h3>
            </div>
            <div className="team_box">
              <img
                src={images.Worker.image}
                alt={images.Worker.alt}
                className="icon"
                loading="lazy"
              />
              <h3 className="title">Workers</h3>
            </div>
            <div className="team_box">
              <img
                src={images.Quality.image}
                alt={images.Quality.alt}
                className="icon"
                loading="lazy"
              />
              <h3 className="title">Quality Inspectors</h3>
            </div>
            <div className="team_box">
              <img
                src={images.Sales.image}
                alt={images.Sales.alt}
                className="icon"
                loading="lazy"
              />
              <h3 className="title">Sales Team</h3>
            </div>
            <div className="team_box">
              <img
                src={images.Service.image}
                alt={images.Service.alt}
                className="icon"
                loading="lazy"
              />
              <h3 className="title">After-Sales Service Team</h3>
            </div>
          </div>
          <div className="why_choose">
            <div className="text_container">
              <h4 className="title">Why Choose Us?</h4>
            </div>
            <div className="row why_choose_row">
              {whyChooseData.map((item, i) => (
                <div className="col-lg-3">
                  <div className="choose_box">
                    <h5 className="title">{item.head}</h5>
                    <p className="desc">{item.desc}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="text_container">
              <h4 className="desc">
                As the prime Industrial Strainers manufacturer in Mumbai, India,
                we also Supply to other regions, including Gujrat, Rajasthan,
                Madhya Pradesh, Uttar Pradesh, Delhi, Kerala, Karnataka, Tamil
                Nadu, Jharkhand, Orissa, West Bengal, and more.
                <br />
                <br />
                For any inquiries or to place your strainers orders, feel free
                to contact us. Our team is always ready to provide more
                information and address any queries you may have.
              </h4>
            </div>
          </div>
          <div className="faqs_container">
            <div class="title">FAQs</div>
            <div class="faq_desc">
              <strong>1. Are you a manufacturer or a trader?</strong>
              <br />
              We are a manufacturer. We have complete production lines and
              skillful workers. All our products are qualified at an affordable
              price, you don't need to worry about buying products of the same
              quality at a high price.
            </div>
            <div class="faq_desc">
              <strong>2. What is Strainer?</strong>
              <br />A strainer is a device used to separate solid particles from
              liquids or gases.
            </div>
            <div class="faq_desc">
              <strong>
                3. What types of strainers does your company manufacture?
              </strong>
              <br />
              Our company manufactures a wide range of strainers, including
              simplex basket strainers, duplex basket strainers, Y-strainers,
              T-strainers, conical strainers, and temporary strainers.
            </div>
            <div class="faq_desc">
              <strong>
                4. Can you provide custom strainer solutions to meet specific
                requirements?
              </strong>
              <br />
              Yes, absolutely! We have a dedicated team of engineers and
              designers who can work with you to create custom strainer
              solutions tailored to your unique needs and specifications.
            </div>
            <div class="faq_desc">
              <strong>
                5. Do you offer installation services for your strainers?
              </strong>
              <br />
              We primarily focus on manufacturing and supplying strainers, but
              we can provide installation guidelines and technical support to
              ensure proper installation and operation of our products.
            </div>
            <div class="faq_desc">
              <strong>
                6. Do you offer any warranties for your strainers?
              </strong>
              <br />
              Yes, we provide warranties on our strainers against manufacturing
              defects. The warranty period may vary depending on the type of
              strainer and its intended use.
            </div>
            <div class="faq_desc">
              <strong>7. Do you ship your strainers internationally?</strong>
              <br />
              Yes, we have experience in shipping our strainers globally. We can
              arrange international shipping to deliver our products to your
              desired location.
            </div>
            <div class="faq_desc">
              <strong>
                8. How can I contact your customer support in case of inquiries
                or issues?
              </strong>
              <br />
              You can contact our customer support team through our website,
              email, or phone. We are always ready to assist you with any
              inquiries or address any issues you may have.
            </div>
            <div class="faq_desc">
              <strong>
                9. Are your strainers compliant with industry standards?
              </strong>
              <br />
              Yes, our strainers are manufactured in accordance with industry
              standards and regulations. They are designed to meet or exceed the
              necessary safety and performance requirements.
            </div>
            <div class="faq_desc">
              <strong>
                10. Are you open to collaboration with distributors or
                wholesalers?
              </strong>
              <br />
              Yes, we are open to collaboration opportunities. If you are
              interested in becoming a distributor or wholesaler of our
              strainers, please contact our business development team to discuss
              further details.
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
