import React, { useEffect, useState } from "react";
import "./productinner.scss";
import MetaDecorator from "../../Components/MetaDecorator/MetaDecorator";
import { Banner } from "../../Components/Banner/Banner";
import {
  contactUs,
  homepage,
  pageNotFound,
} from "../../helpers/constant-words";
import { findProductBySlug } from "../../Data/productsData";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImageWithFallback from "../../Components/ImageWithFallback/ImageWithFallback";
import { useWindowSize } from "react-use";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { logowhite } from "../../images";
// import FsLightbox from "fslightbox-react";

const ProductInner = () => {
  const { width } = useWindowSize();
  let { productSlug } = useParams();
  const [product, setProduct] = useState({});
  const [coming, setComing] = useState("");
  const [toggler, setToggler] = useState(false);
  const [img, setImg] = useState();
  let navigate = useNavigate();
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    sourceIndex: 0,
  });
  const images = product.gallery?.map(({ jpg }) => {
    return jpg;
  });

  function openLightboxOnSlide(index) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      sourceIndex: index,
    });
  }

  useEffect(() => {
    const productData = findProductBySlug(productSlug);

    if (!productData) {
      navigate(pageNotFound);
    } else {
      setProduct(productData);
    }
  }, [productSlug, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [productSlug]);

  return (
    <>
      <MetaDecorator
        metaTitle={product?.metaTitle}
        metaDesc={product?.desc}
        canonicalLink={window.location.href}
      />
      <Banner
        breadcrumbWord1="Home"
        link1={homepage}
        breadcrumbWord3={`${
          product?.parentSlug !== "scs" && product?.parentSlug !== "mto"
            ? "products"
            : ""
        }`}
        link3={`/products/${product?.parentSlug}`}
        breadcrumbWord4={product?.title}
        disabled
      />
      <section className="inner_sec1">
        <div className="my_container">
          {product?.ticker !== "" && (
            <div className="text_container">
              <h1 className="title main_title">{product?.ticker}</h1>
            </div>
          )}
          <div className="row">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-md-6 order-md-2">
                  <div className="img_wrapper">
                    <ImageWithFallback
                      src={product?.image}
                      fallbackSrc={product?.fallImage}
                      alt={product?.alt}
                      className="product_img"
                    />
                  </div>
                </div>
                <div className="col-md-6 order-md-1">
                  <div className="text_container">
                    <h2 className="title">{product?.title}</h2>
                    <p
                      className="desc"
                      dangerouslySetInnerHTML={{ __html: product?.desc }}
                    />
                    <ul className="feature_wrap">
                      {product.key?.map((item, i) => (
                        <li className="feature" key={i}>
                          <strong>{item.title} </strong>
                          {item.desc}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="text_container">
                <p
                  className="desc"
                  dangerouslySetInnerHTML={{ __html: product?.desc2 }}
                />
              </div>
              {/* <div className="product_gallery">
                {width > 992 ? (
                  <div className="row">
                    {product.gallery?.map((image, i) => (
                      <div className="col-md-3" key={i}>
                        <ImageWithFallback
                          width={600}
                          height={600}
                          src={image.webp}
                          fallbackSrc={image.jpg}
                          alt={image.alt}
                          className="gallery_img"
                          onClick={() => openLightboxOnSlide(i)}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <Swiper
                    modules={[Autoplay]}
                    slidesPerView={1.2}
                    spaceBetween={20}
                    autoplay={{
                      delay: 1500,
                    }}
                    breakpoints={{
                      0: {
                        slidesPerView: 1.2,
                      },
                      600: {
                        slidesPerView: 2.7,
                      },
                    }}
                  >
                    {product.gallery?.map((image, i) => (
                      <SwiperSlide key={i}>
                        <ImageWithFallback
                          width={600}
                          height={600}
                          src={image.webp}
                          fallbackSrc={image.jpg}
                          alt={image.alt}
                          className="gallery_img"
                          onClick={() => openLightboxOnSlide(i)}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div> */}
              <div className="documents_wrapper">
                <div className="row">
                  {product.documents?.map((item, i) => (
                    <div className="col-sm-4 col-md-4 col-lg-3" key={i}>
                      <div className="cta_wrapper">
                        <a
                          href={item.url !== "" ? item.url : () => false}
                          target={
                            item.title === "E - Catalogue" ? "_blank" : ""
                          }
                        >
                          <button
                            className="common_button"
                            onMouseOver={() =>
                              setComing(item.url === "" ? i : "")
                            }
                            onMouseLeave={() => setComing("")}
                          >
                            {coming === i ? "Coming Soon" : item.title}
                          </button>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="sticky_contact_box">
                <img src={logowhite} alt="white logo" className="logo" />
                {/* <h2 className="title">ASRE ENGINEERING</h2> */}
                <div className="text_container">
                  <h4 className="heading">Call Us On</h4>
                  <p className="desc">
                    <a className="number" href="tel:+91 7208148600">
                      +91 7208148600
                    </a>
                    <br />
                    <a className="number" href="tel:+91 9323568110">
                      +91 9323568110
                    </a>
                  </p>{" "}
                  <h4 className="heading">Write Us On</h4>
                  <p className="desc">
                    <a
                      className="number"
                      href="mailto:mail@asreengineering.com"
                    >
                      mail@asreengineering.com
                    </a>
                    <br />
                    <a
                      className="number"
                      href="mailto:asreengineering@gmail.com"
                    >
                      asreengineering@gmail.com
                    </a>
                  </p>
                  <div className="cta_wrapper">
                    <Link to={contactUs}>
                      <button to={contactUs} className="common_button">
                        Know More
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <FsLightbox
        toggler={lightboxController.toggler}
        sources={images}
        sourceIndex={lightboxController.sourceIndex}
      /> */}
    </>
  );
};

export default ProductInner;
