import { images } from "../images";

//new
export const banners = [
  {
    id: 1,
    backimg: images.banner01.image,
    mobImg: images.banner01mob.image,
    alt: images.banner01.alt,
    // title: "Strainer Manufacturer",
    // desc: "Banner 01",
  },
  {
    id: 3,
    backimg: images.banner03.image,
    mobImg: images.banner03mob.image,
    alt: images.banner03.alt,
    // title: "Strainer Manufacturer",
    // desc: "Banner 03",
  },
  {
    id: 4,
    backimg: images.banner04.image,
    mobImg: images.banner04mob.image,
    alt: images.banner04.alt,
    // title: "Strainer Manufacturer",
    // desc: "Banner 04",
  },
  {
    id: 2,
    backimg: images.banner02.image,
    mobImg: images.banner02mob.image,
    alt: images.banner02.alt,
    // title: "Strainer Manufacturer",
    // desc: "Banner 02",
  },
];

export const aboutArr = [
  {
    img: images.homeAbout.image,
    fallback: images.homeAboutJpg.image,
    imgMob: images.homeAboutmob.image,
    fallbackMob: images.homeAboutJpgmob.mobimage,
    alt: images.homeAbout.alt,
  },
];

export const ourservicesArr = [
  "Superior quality",
  "Wider range availability",
  "The entire range as per latest standard",
  "Longer shelf life",
  "Made using non corrosive materials",
  "Can withstand tough condition",
  "Filter and separate unwanted particles and element",
  "Easy customization",
  "Available at exclusive prices",
  "Require less maintenance",
  "Easy customization",
];

export const advantagessArr = [
  "Premium quality strainers is manufactured by us",
  "We provide a wider range of the entire range",
  "Our company mainly focus on the product quality",
  "We are highly expertise at our work",
  "Highly professional and expertise team",
  "Timely delivery before allotted date",
  "Great R&D team which work constantly in innovating and emphasize the quality of the product",
  "Available at exclusive prices",
  "The entire range is as per international standard which promotes their usage nationally and internationally",
  "Our company use best quality raw materials and modern technology for production of our entire range",
  "Easy customization facility is provided",
];

export const secretData = [
  {
    img: images.secretmanufacturer.image,
    alt: images.secretmanufacturer.alt,
    title: "Manufacturer",
    desc: "Asre Engineering is the Leading Manufacturer of premium Quality Industrial Strainers ,Filters & Accessories.",
  },
  {
    img: images.secretstock.image,
    alt: images.secretstock.alt,
    title: "Stock Holder",
    desc: "Asre Engineering is the Leading Stockholder and Supplier of High Quality Strainers & Filters.",
  },
  {
    img: images.secretquick.image,
    alt: images.secretquick.alt,
    title: "Quick Response",
    desc: "ASRE Team has professional products Knowledge and Experience. You will Receive Quick Response and Quotation for your requirements.",
  },
  {
    img: images.secretontimedelivery.image,
    alt: images.secretontimedelivery.alt,
    title: "On Time Delivery",
    desc: "No Matter where Our Clients are situated nationally or internationally,  we Value our and their Time, So we provide on time delivery with Quality Products.",
  },
  {
    img: images.secretquality.image,
    alt: images.secretquality.alt,
    title: "Quality Policy",
    desc: "Asre Engineering is An ISO 9001 Certified Company. We believe strict quality control is an most responsible behavior for our customers and a key factor that we win our Clients Trust.",
  },
];

export const segmentData = [
  {
    img: images.segmentWaterPipe.image,
    alt: images.segmentWaterPipe.alt,
    title: "Oil & Gas",
  },
  {
    img: images.segmentDistillatory.image,
    alt: images.segmentDistillatory.alt,
    title: "Sugar & Distilleries",
  },
  {
    img: images.segmentBrewing.image,
    alt: images.segmentBrewing.alt,
    title: "Food & Beverages",
  },
  {
    img: images.segmentPulppaper.image,
    alt: images.segmentPulppaper.alt,
    title: "Pulp & Paper",
  },
  {
    img: images.segmentFactoryplant.image,
    alt: images.segmentFactoryplant.alt,
    title: "Pharmaceutical",
  },
  {
    img: images.segmentSurrey.image,
    alt: images.segmentSurrey.alt,
    title: "Slurry & Ash Handling",
  },
  {
    img: images.segmentWatertreatment.image,
    alt: images.segmentWatertreatment.alt,
    title: "Water Treatment",
  },
  {
    img: images.segmentVarnish.image,
    alt: images.segmentVarnish.alt,
    title: "Paint & Varnishes",
  },
  {
    img: images.segmentsteel.image,
    alt: images.segmentsteel.alt,
    title: "Steel & Power",
  },
  {
    img: images.segmentChemicalind.image,
    alt: images.segmentChemicalind.alt,
    title: "Chemical Industry",
  },
];

export const galleryData = [
  {
    webp: images.homeBS1.image,
    jpg: images.homeBS1.image,
    alt: images.homeBS1.alt,
  },
  {
    webp: images.homeYT1.image,
    jpg: images.homeYT1.image,
    alt: images.homeYT1.alt,
  },
  {
    webp: images.homeDS1.image,
    jpg: images.homeDS1.image,
    alt: images.homeDS1.alt,
  },

  {
    webp: images.homeBS2.image,
    jpg: images.homeBS2.image,
    alt: images.homeBS2.alt,
  },
  {
    webp: images.homeYT2.image,
    jpg: images.homeYT2.image,
    alt: images.homeYT2.alt,
  },
  {
    webp: images.homeDS2.image,
    jpg: images.homeDS2.image,
    alt: images.homeDS2.alt,
  },

  {
    webp: images.homeBS3.image,
    jpg: images.homeBS3.image,
    alt: images.homeBS3.alt,
  },
  {
    webp: images.homeYT3.image,
    jpg: images.homeYT3.image,
    alt: images.homeYT3.alt,
  },
  {
    webp: images.homeDS3.image,
    jpg: images.homeDS3.image,
    alt: images.homeDS3.alt,
  },

  {
    webp: images.homeBS4.image,
    jpg: images.homeBS4.image,
    alt: images.homeBS4.alt,
  },
  {
    webp: images.homeYT4.image,
    jpg: images.homeYT4.image,
    alt: images.homeYT4.alt,
  },
  {
    webp: images.homeDS4.image,
    jpg: images.homeDS4.image,
    alt: images.homeDS4.alt,
  },

  {
    webp: images.homeBS5.image,
    jpg: images.homeBS5.image,
    alt: images.homeBS5.alt,
  },
  {
    webp: images.homeYT5.image,
    jpg: images.homeYT5.image,
    alt: images.homeYT5.alt,
  },
  {
    webp: images.homeDS5.image,
    jpg: images.homeDS5.image,
    alt: images.homeDS5.alt,
  },

  {
    webp: images.homeBS6.image,
    jpg: images.homeBS6.image,
    alt: images.homeBS6.alt,
  },
  {
    webp: images.homeYT6.image,
    jpg: images.homeYT6.image,
    alt: images.homeYT6.alt,
  },
  {
    webp: images.homeDS6.image,
    jpg: images.homeDS6.image,
    alt: images.homeDS6.alt,
  },

  {
    webp: images.homeBS7.image,
    jpg: images.homeBS7.image,
    alt: images.homeBS7.alt,
  },
  {
    webp: images.homeYT7.image,
    jpg: images.homeYT7.image,
    alt: images.homeYT7.alt,
  },
  {
    webp: images.homeDS7.image,
    jpg: images.homeDS7.image,
    alt: images.homeDS7.alt,
  },

  {
    webp: images.homeBS8.image,
    jpg: images.homeBS8.image,
    alt: images.homeBS8.alt,
  },
  {
    webp: images.homeYT8.image,
    jpg: images.homeYT8.image,
    alt: images.homeYT8.alt,
  },
  {
    webp: images.homeDS8.image,
    jpg: images.homeDS8.image,
    alt: images.homeDS8.alt,
  },

  {
    webp: images.homeBS9.image,
    jpg: images.homeBS9.image,
    alt: images.homeBS9.alt,
  },
  {
    webp: images.homeYT9.image,
    jpg: images.homeYT9.image,
    alt: images.homeYT9.alt,
  },
  {
    webp: images.homeDS9.image,
    jpg: images.homeDS9.image,
    alt: images.homeDS9.alt,
  },

  {
    webp: images.homeBS10.image,
    jpg: images.homeBS10.image,
    alt: images.homeBS10.alt,
  },
  {
    webp: images.homeYT10.image,
    jpg: images.homeYT10.image,
    alt: images.homeYT10.alt,
  },
  {
    webp: images.homeDS10.image,
    jpg: images.homeDS10.image,
    alt: images.homeDS10.alt,
  },

  {
    webp: images.homeBS11.image,
    jpg: images.homeBS11.image,
    alt: images.homeBS11.alt,
  },
  {
    webp: images.homeYT11.image,
    jpg: images.homeYT11.image,
    alt: images.homeYT11.alt,
  },
  {
    webp: images.homeDS11.image,
    jpg: images.homeDS11.image,
    alt: images.homeDS11.alt,
  },

  {
    webp: images.homeBS12.image,
    jpg: images.homeBS12.image,
    alt: images.homeBS12.alt,
  },
  {
    webp: images.homeYT12.image,
    jpg: images.homeYT12.image,
    alt: images.homeYT12.alt,
  },
  {
    webp: images.homeDS12.image,
    jpg: images.homeDS12.image,
    alt: images.homeDS12.alt,
  },

  {
    webp: images.homeBS13.image,
    jpg: images.homeBS13.image,
    alt: images.homeBS13.alt,
  },
  {
    webp: images.homeYT13.image,
    jpg: images.homeYT13.image,
    alt: images.homeYT13.alt,
  },
  {
    webp: images.homeDS13.image,
    jpg: images.homeDS13.image,
    alt: images.homeDS13.alt,
  },

  {
    webp: images.homeBS14.image,
    jpg: images.homeBS14.image,
    alt: images.homeBS14.alt,
  },
  {
    webp: images.homeYT14.image,
    jpg: images.homeYT14.image,
    alt: images.homeYT14.alt,
  },
  {
    webp: images.homeDS14.image,
    jpg: images.homeDS14.image,
    alt: images.homeDS14.alt,
  },

  {
    webp: images.homeBS15.image,
    jpg: images.homeBS15.image,
    alt: images.homeBS15.alt,
  },
  {
    webp: images.homeYT15.image,
    jpg: images.homeYT15.image,
    alt: images.homeYT15.alt,
  },
  {
    webp: images.homeDS15.image,
    jpg: images.homeDS15.image,
    alt: images.homeDS15.alt,
  },

  {
    webp: images.homeBS16.image,
    jpg: images.homeBS16.image,
    alt: images.homeBS16.alt,
  },
  {
    webp: images.homeYT16.image,
    jpg: images.homeYT16.image,
    alt: images.homeYT16.alt,
  },

  {
    webp: images.homeBS17.image,
    jpg: images.homeBS17.image,
    alt: images.homeBS17.alt,
  },
  {
    webp: images.homeYT17.image,
    jpg: images.homeYT17.image,
    alt: images.homeYT17.alt,
  },

  {
    webp: images.homeBS18.image,
    jpg: images.homeBS18.image,
    alt: images.homeBS18.alt,
  },
  {
    webp: images.homeYT18.image,
    jpg: images.homeYT18.image,
    alt: images.homeYT18.alt,
  },

  {
    webp: images.homeBS19.image,
    jpg: images.homeBS19.image,
    alt: images.homeBS19.alt,
  },
  {
    webp: images.homeYT19.image,
    jpg: images.homeYT19.image,
    alt: images.homeYT19.alt,
  },

  {
    webp: images.homeBS20.image,
    jpg: images.homeBS20.image,
    alt: images.homeBS20.alt,
  },
  {
    webp: images.homeYT20.image,
    jpg: images.homeYT20.image,
    alt: images.homeYT20.alt,
  },

  {
    webp: images.homeBS21.image,
    jpg: images.homeBS21.image,
    alt: images.homeBS21.alt,
  },
  {
    webp: images.homeYT21.image,
    jpg: images.homeYT21.image,
    alt: images.homeYT21.alt,
  },

  {
    webp: images.homeBS22.image,
    jpg: images.homeBS22.image,
    alt: images.homeBS22.alt,
  },
  {
    webp: images.homeYT22.image,
    jpg: images.homeYT22.image,
    alt: images.homeYT22.alt,
  },

  {
    webp: images.homeBS23.image,
    jpg: images.homeBS23.image,
    alt: images.homeBS23.alt,
  },
  {
    webp: images.homeYT23.image,
    jpg: images.homeYT23.image,
    alt: images.homeYT23.alt,
  },

  {
    webp: images.homeBS24.image,
    jpg: images.homeBS24.image,
    alt: images.homeBS24.alt,
  },
  {
    webp: images.homeYT24.image,
    jpg: images.homeYT24.image,
    alt: images.homeYT24.alt,
  },

  {
    webp: images.homeBS25.image,
    jpg: images.homeBS25.image,
    alt: images.homeBS25.alt,
  },
  {
    webp: images.homeYT25.image,
    jpg: images.homeYT25.image,
    alt: images.homeYT25.alt,
  },

  {
    webp: images.homeBS26.image,
    jpg: images.homeBS26.image,
    alt: images.homeBS26.alt,
  },
  {
    webp: images.homeYT26.image,
    jpg: images.homeYT26.image,
    alt: images.homeYT26.alt,
  },

  {
    webp: images.homeBS27.image,
    jpg: images.homeBS27.image,
    alt: images.homeBS27.alt,
  },
  {
    webp: images.homeYT27.image,
    jpg: images.homeYT27.image,
    alt: images.homeYT27.alt,
  },

  {
    webp: images.homeBS28.image,
    jpg: images.homeBS28.image,
    alt: images.homeBS28.alt,
  },
  {
    webp: images.homeYT28.image,
    jpg: images.homeYT28.image,
    alt: images.homeYT28.alt,
  },

  {
    webp: images.homeBS29.image,
    jpg: images.homeBS29.image,
    alt: images.homeBS29.alt,
  },
  {
    webp: images.homeYT29.image,
    jpg: images.homeYT29.image,
    alt: images.homeYT29.alt,
  },

  {
    webp: images.homeBS30.image,
    jpg: images.homeBS30.image,
    alt: images.homeBS30.alt,
  },
  {
    webp: images.homeBS31.image,
    jpg: images.homeBS31.image,
    alt: images.homeBS31.alt,
  },
  {
    webp: images.homeBS32.image,
    jpg: images.homeBS32.image,
    alt: images.homeBS32.alt,
  },
  {
    webp: images.homeBS33.image,
    jpg: images.homeBS33.image,
    alt: images.homeBS33.alt,
  },
  {
    webp: images.homeBS34.image,
    jpg: images.homeBS34.image,
    alt: images.homeBS34.alt,
  },
  {
    webp: images.homeBS35.image,
    jpg: images.homeBS35.image,
    alt: images.homeBS35.alt,
  },
  {
    webp: images.homeBS36.image,
    jpg: images.homeBS36.image,
    alt: images.homeBS36.alt,
  },
  {
    webp: images.homeBS37.image,
    jpg: images.homeBS37.image,
    alt: images.homeBS37.alt,
  },

  //Filters images
  {
    webp: images.homeFilter1.image,
    jpg: images.homeFilter1.image,
    alt: images.homeFilter1.alt,
  },
  {
    webp: images.homeFilter2.image,
    jpg: images.homeFilter2.image,
    alt: images.homeFilter2.alt,
  },
  {
    webp: images.homeFilter3.image,
    jpg: images.homeFilter3.image,
    alt: images.homeFilter3.alt,
  },
  {
    webp: images.homeFilter4.image,
    jpg: images.homeFilter4.image,
    alt: images.homeFilter4.alt,
  },
  {
    webp: images.homeFilter5.image,
    jpg: images.homeFilter5.image,
    alt: images.homeFilter5.alt,
  },
  {
    webp: images.homeFilter6.image,
    jpg: images.homeFilter6.image,
    alt: images.homeFilter6.alt,
  },
  {
    webp: images.homeFilter7.image,
    jpg: images.homeFilter7.image,
    alt: images.homeFilter7.alt,
  },
  {
    webp: images.homeFilter8.image,
    jpg: images.homeFilter8.image,
    alt: images.homeFilter8.alt,
  },
  {
    webp: images.homeFilter9.image,
    jpg: images.homeFilter9.image,
    alt: images.homeFilter9.alt,
  },
  {
    webp: images.homeFilter10.image,
    jpg: images.homeFilter10.image,
    alt: images.homeFilter10.alt,
  },
  {
    webp: images.homeFilter11.image,
    jpg: images.homeFilter11.image,
    alt: images.homeFilter11.alt,
  },
  {
    webp: images.homeFilter12.image,
    jpg: images.homeFilter12.image,
    alt: images.homeFilter12.alt,
  },
  {
    webp: images.homeFilter13.image,
    jpg: images.homeFilter13.image,
    alt: images.homeFilter13.alt,
  },
  {
    webp: images.homeFilter14.image,
    jpg: images.homeFilter14.image,
    alt: images.homeFilter14.alt,
  },
  {
    webp: images.homeFilter15.image,
    jpg: images.homeFilter15.image,
    alt: images.homeFilter15.alt,
  },
  {
    webp: images.homeFilter16.image,
    jpg: images.homeFilter16.image,
    alt: images.homeFilter16.alt,
  },
  {
    webp: images.homeFilter17.image,
    jpg: images.homeFilter17.image,
    alt: images.homeFilter17.alt,
  },
  {
    webp: images.homeFilter18.image,
    jpg: images.homeFilter18.image,
    alt: images.homeFilter18.alt,
  },

  // POT images

  {
    webp: images.homePot1.image,
    jpg: images.homePot1.image,
    alt: images.homePot1.alt,
  },
  {
    webp: images.homePot2.image,
    jpg: images.homePot2.image,
    alt: images.homePot2.alt,
  },
  {
    webp: images.homePot3.image,
    jpg: images.homePot3.image,
    alt: images.homePot3.alt,
  },
  {
    webp: images.homePot4.image,
    jpg: images.homePot4.image,
    alt: images.homePot4.alt,
  },
];

export const whyChooseData = [
  {
    head: "Great Expertise:",
    desc: "With over 25+ years of experience, we are experts in providing the best Industrial Strainers, Filters and Accessoriesn our locality.",
  },
  {
    head: "24x7 Customer Support:",
    desc: "Our executive team is available round-the-clock to assist our national and international clientele with their requirements.",
  },
  {
    head: "On-Time Delivery:",
    desc: "We value our clients' time and ensure on-time delivery, regardless of their location.",
  },
  {
    head: "R&D Department:",
    desc: "Our dedicated R&D department continually innovates new designs and technologies to meet customer demands with top-quality products.",
  },
  {
    head: "Expert Team:",
    desc: "Our highly skilled team ensures meticulous attention to detail, manufacturing and supplying the best quality strainers to our customers.",
  },
  {
    head: "Well-Developed Infrastructure:",
    desc: "Equipped with state-of-the-art facilities, our manufacturing unit and warehouse provide an excellent working environment for our employees.",
  },
  {
    head: "Quality Assurance:",
    desc: "We use only the finest raw materials for our strainer designs, ensuring exceptional product quality. Each product undergoes rigorous quality assurance testing before being supplied and exported worldwide.",
  },
];
