import React, { useEffect, useState } from "react";
import "./footer.scss";
import { Link, NavLink } from "react-router-dom";
import { useWindowSize } from "react-use";
import {
  rightarrowblue,
  callwhite,
  mailwhite,
  whitedownarrow,
  logowhite,
  images,
  isocertificate,
  catalogueicon,
  fbicon,
  linkedinicon,
  yticon,
  twticon,
  eBrochure,
  pinteresticon,
} from "../../images";
import {
  aboutUs,
  contactUs,
  devLink,
  facebookURL,
  filtersURL,
  homepage,
  linkedinURL,
  madetoorderURL,
  pinterestURL,
  selfcleaningURL,
  sitemapURL,
  strainersURL,
  termsURL,
  xURL,
  youtubeURL,
} from "../../helpers/constant-words";
import { Accordion } from "react-bootstrap";
import {
  findAllProducts,
  findCategoryBySlug,
  productsArr,
} from "../../Data/productsData";

const Footer = () => {
  const { width: windowWidth } = useWindowSize();
  const [strainers, setStrainers] = useState([]);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    const strainerSlug = findCategoryBySlug(strainersURL);
    const filterSlug = findCategoryBySlug(filtersURL);

    const strainersData = findAllProducts(strainerSlug?.slug);
    const filtersData = findAllProducts(filterSlug?.slug);
    setStrainers(strainersData);
    setFilters(filtersData);
  }, []);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {windowWidth > 1023 ? (
        <footer className="footer">
          <img
            src={rightarrowblue}
            alt="asre up arrow"
            loading="lazy"
            className="footeruparrow"
            onClick={handleClick}
          />
          <div className="my_container footer_first_row">
            <div className="row m-0">
              <div className="col-md-3 logo_container">
                <NavLink to={homepage} onClick={handleClick}>
                  <img
                    src={logowhite}
                    alt="asre footer logo"
                    loading="lazy"
                    className="footer_logo"
                  />
                </NavLink>
                <div className="contact_info">
                  <p className="contact_title">GET IN TOUCH</p>
                  <div className="call_info info">
                    <div className="call_icon">
                      <img src={callwhite} loading="lazy" alt="call" />
                    </div>
                    <div className="text">
                      <span className="number">
                        <a href="tel:+917208148600">+91 7208148600</a>
                      </span>
                    </div>
                  </div>
                  <div className="mail_info info">
                    <div className="mail_icon">
                      <img src={mailwhite} loading="lazy" alt="mail" />
                    </div>
                    <div className="text">
                      <span className="number">
                        <a href="mailto:mail@asreengineering.com">
                          mail@asreengineering.com
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="mail_info info">
                    <div className="catalogue_icon">
                      <img src={catalogueicon} loading="lazy" alt="catalogue" />
                    </div>
                    <div className="text">
                      <span className="number">
                        <a href={eBrochure} target="_blank" rel="noreferrer">
                          E-Catalogue
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="mail_info social_flex">
                    <a
                      href={facebookURL}
                      target="_blank"
                      rel="noreferrer"
                      className="social_icon"
                    >
                      <img src={fbicon} loading="lazy" alt="Facebook" />
                    </a>
                    <a
                      href={linkedinURL}
                      target="_blank"
                      rel="noreferrer"
                      className="social_icon"
                    >
                      <img src={linkedinicon} loading="lazy" alt="Linkedin" />
                    </a>
                    <a
                      href={youtubeURL}
                      target="_blank"
                      rel="noreferrer"
                      className="social_icon"
                    >
                      <img src={yticon} loading="lazy" alt="YouTube" />
                    </a>
                    <a
                      href={pinterestURL}
                      target="_blank"
                      rel="noreferrer"
                      className="social_icon"
                    >
                      <img src={pinteresticon} loading="lazy" alt="Pinterest" />
                    </a>
                    <a
                      href={xURL}
                      target="_blank"
                      rel="noreferrer"
                      className="social_icon"
                    >
                      <img src={twticon} loading="lazy" alt="Twitter" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="box_title">Strainers</div>

                <div className="box_content_wrapper">
                  {strainers.map(
                    (item, i) =>
                      i <= 3 && (
                        <div className="link_name_wrapper">
                          <Link
                            key={item.id}
                            to={`products/${strainersURL}/${item.slug}`}
                            className="link_name"
                          >
                            {item.title}
                          </Link>
                        </div>
                      )
                  )}
                  {strainers.length > 3 && (
                    <div className="link_name_wrapper">
                      <Link
                        to={`products/${strainersURL}`}
                        className="link_name"
                      >
                        All Strainers
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-2">
                <div className="box_title">Filters</div>

                <div className="box_content_wrapper">
                  {filters.map(
                    (item, i) =>
                      i <= 3 && (
                        <div className="link_name_wrapper">
                          <Link
                            key={item.id}
                            to={`products/${filtersURL}/${item.slug}`}
                            className="link_name"
                          >
                            {item.title}
                          </Link>
                        </div>
                      )
                  )}
                  {filters.length > 3 && (
                    <div className="link_name_wrapper">
                      <Link to={`products/${filtersURL}`} className="link_name">
                        All Filters
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-2">
                <div className="box_title">Quick Links</div>
                <div className="box_content_wrapper">
                  <div className="link_name_wrapper">
                    <NavLink
                      to={`products/${selfcleaningURL}/${productsArr[10]?.slug}`}
                      className="link_name"
                    >
                      Self Cleaning Strainer
                    </NavLink>
                  </div>
                  <div className="link_name_wrapper">
                    <NavLink
                      to={`products/${madetoorderURL}/${productsArr[11]?.slug}`}
                      className="link_name"
                    >
                      Made to Order
                    </NavLink>
                  </div>
                  <div className="link_name_wrapper">
                    <NavLink to={aboutUs} className="link_name">
                      About Us
                    </NavLink>
                  </div>
                  <div className="link_name_wrapper">
                    <NavLink to={contactUs} className="link_name">
                      Contact
                    </NavLink>
                  </div>
                  <div className="link_name_wrapper">
                    <NavLink to={termsURL} className="link_name">
                      Terms & Conditions
                    </NavLink>
                  </div>
                  <div className="link_name_wrapper">
                    <NavLink to={sitemapURL} className="link_name">
                      Sitemap
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-md-3 p-0">
                {/* <iframe
                  title="Asre Engineering Geo Location"
                  className="google_map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.4952170466995!2d73.0216004747502!3d19.173560948903415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bf13e0f69b0f%3A0x3d79aa9fc5ec5014!2sAsre%20Engineering%20-%20Strainer%20Manufacturers%20in%20Mumbai.%20Basket%20Type%20Strainers%2C%20Y%20Strainers%20%2C%20Y%20type%20Strainer.!5e0!3m2!1sen!2sin!4v1690696618871!5m2!1sen!2sin"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe> */}
                <div className="certificate">
                  <a href={isocertificate} target="_blank" rel="noreferrer">
                    <img
                      src={images.isocertified.image}
                      alt={images.isocertified.alt}
                      className="iso_certified"
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="my_container footer_second_row">
            <div className="footer_second_content">
              <div className="row m-0 second_row_content">
                <div className="col-lg-6 text-left">
                  Copyrights © 1999 - {new Date().getFullYear()} All Rights
                  Reserved Asre Engineering
                </div>
                <div className="col-lg-6 text-right copyright_text">
                  Design & Developed by{" "}
                  <a href={devLink} target="_blank" rel="noreferrer">
                    AsreSoft
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      ) : (
        <footer className="footer">
          <img
            src={rightarrowblue}
            alt="asre up arrow"
            loading="lazy"
            className="footeruparrow"
            onClick={handleClick}
          />
          <div className="my_container footer_first_row">
            <div className="row m-0">
              <div className="col-lg-3 ft_logo">
                <div className="certificate">
                  <NavLink to={homepage} onClick={handleClick}>
                    <img
                      src={logowhite}
                      alt="asre footer logo"
                      loading="lazy"
                      className="footer_logo"
                    />
                  </NavLink>
                  <a href={isocertificate} target="_blank" rel="noreferrer">
                    <img
                      src={images.isocertified.image}
                      alt={images.isocertified.alt}
                      className="iso_certified"
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 ft_content">
                <div className="footer_contact_details_container">
                  <div className="contact_info">
                    <p className="contact_title">GET IN TOUCH</p>
                    <div className="call_info info">
                      <div className="icon">
                        <img src={callwhite} loading="lazy" alt="call" />
                      </div>
                      <div className="text">
                        <span className="number">
                          <a href="tel:+917208148600">+91 7208148600</a>
                        </span>
                      </div>
                    </div>
                    <div className="mail_info info">
                      <div className="icon">
                        <img src={mailwhite} loading="lazy" alt="mail" />
                      </div>
                      <div className="text">
                        <span className="number">
                          <a href="mailto:mail@asreengineering.com">
                            mail@asreengineering.com
                          </a>
                        </span>
                      </div>
                    </div>
                    <div className="mail_info info">
                      <div className="catalogue_icon">
                        <img
                          src={catalogueicon}
                          loading="lazy"
                          alt="catalogue"
                        />
                      </div>
                      <div className="text">
                        <span className="number">
                          <a href={eBrochure} target="_blank" rel="noreferrer">
                            E-Catalogue
                          </a>
                        </span>
                      </div>
                    </div>
                    <div className="mail_info social_flex">
                      <a
                        href={facebookURL}
                        target="_blank"
                        rel="noreferrer"
                        className="social_icon"
                      >
                        <img src={fbicon} loading="lazy" alt="Facebook" />
                      </a>
                      <a
                        href={linkedinURL}
                        target="_blank"
                        rel="noreferrer"
                        className="social_icon"
                      >
                        <img src={linkedinicon} loading="lazy" alt="Linkedin" />
                      </a>
                      <a
                        href={youtubeURL}
                        target="_blank"
                        rel="noreferrer"
                        className="social_icon"
                      >
                        <img src={yticon} loading="lazy" alt="YouTube" />
                      </a>
                      <a
                        href={pinterestURL}
                        target="_blank"
                        rel="noreferrer"
                        className="social_icon"
                      >
                        <img
                          src={pinteresticon}
                          loading="lazy"
                          alt="Pinterest"
                        />
                      </a>
                      <a
                        href={xURL}
                        target="_blank"
                        rel="noreferrer"
                        className="social_icon"
                      >
                        <img src={twticon} loading="lazy" alt="X" />
                      </a>
                    </div>
                  </div>
                  <div className="projects">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <p className="ong_pr">STRAINERS</p>
                          <img
                            className="float-right icon"
                            src={whitedownarrow}
                            alt="arrow icon"
                          />
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            {strainers.map((item, i) => (
                              <li>
                                <Link
                                  key={item.id}
                                  to={`products/${strainersURL}/${item.slug}`}
                                >
                                  {item.title}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <p className="ong_pr">FILTERS</p>
                          <img
                            className="float-right icon"
                            src={whitedownarrow}
                            alt="arrow icon"
                          />
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            {filters.map((item, i) => (
                              <li>
                                <Link
                                  key={item.id}
                                  to={`products/${filtersURL}/${item.slug}`}
                                >
                                  {item.title}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          <p className="ong_pr">QUICK LINKS</p>
                          <img
                            className="float-right icon"
                            src={whitedownarrow}
                            alt="arrow icon"
                          />
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>
                              <Link
                                to={`products/${selfcleaningURL}/${productsArr[10]?.slug}`}
                              >
                                Self Cleaning Strainer
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`products/${madetoorderURL}/${productsArr[11]?.slug}`}
                              >
                                Made to Order
                              </Link>
                            </li>
                            <li>
                              <Link to={aboutUs}>About</Link>
                            </li>
                            <li>
                              <Link to={contactUs}>Contact</Link>
                            </li>
                            <li>
                              <Link to={termsURL}>Terms & Conditions</Link>
                            </li>
                            <li>
                              <Link to={sitemapURL}>Sitemap</Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_second_row">
            <div className="my_container">
              <div className="row m-0 second_row_content">
                <div className="col-lg-6 copyright_text">
                  Copyrights © 1999 - {new Date().getFullYear()} All Rights
                  Reserved Asre Engineering
                </div>
                <div className="col-lg-6 copyright_text">
                  Design & Developed by{" "}
                  <a href={devLink} target="_blank" rel="noreferrer">
                    AsreSoft
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
