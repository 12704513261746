import React, { useEffect } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { callicon, images, locationicon, mailiconcontact } from "../../images";
import "./contactus.scss";
import MetaDecorator from "../../Components/MetaDecorator/MetaDecorator";
import { Link } from "react-router-dom";
import { homepage } from "../../helpers/constant-words";

const countriesArr = [
  "Algeria",
  "Australia",
  "Austria",
  "Bahrain",
  "Bangladesh",
  "Brazil",
  "Belgium",
  "Canada",
  "Chile",
  "Czech Republic",
  "Denmark",
  "Egypt",
  "Finland",
  "Germany",
  "Italy",
  "Jordan",
  "Kuwait",
  "Malaysia",
  "Mexico",
  "Morocco",
  "NetherLand",
  "New ZeaLand",
  "Oman",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Saudi Arabia",
  "Singapore",
  "Slovakia",
  "South Africa",
  "Spain",
  "Sweden",
  "Thailand",
  "UAE",
  "UK",
  "USA",
  "Venezuela",
  "Zambia",
  "France",
  "England",
  "Japan",
  "South Korea",
  "Tunisia",
  "Ivory Coast",
  "Argentina",
  "Armenia",
  "Bulgaria",
  "Cuba",
  "Ecuador",
  "Estonia",
  "Georgia",
  "Greece",
  "Indonesia",
  "Norway",
  "Panama",
  "Peru",
  "Slovenia",
  "Switzerland",
  "Turkey",
];

const ContactUs = () => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        metaTitle="Contact Strainer Manufacturer - ASRE ENGINEERING"
        metaDesc="Contact Strainer Manufacturer - ASRE ENGINEERING"
        canonicalLink={window.location.href}
      />
      <section className="contact_sec2" data-aos="fade-in">
        <div
          className="banner"
          style={{
            backgroundImage: `url(${images.contactban.image})`,
            backgroundSize: "cover",
            minHeight: "30em",
            backgroundPosition: "center",
          }}
        >
          <div className="breadcrumb_wrapper">
            <div className="common_breadcrumb_line1">
              <Link to={homepage} className="common_breadcrumb_link">
                Home
              </Link>
            </div>
            <div className="common_breadcrumb_line2">Contact Us</div>
          </div>
        </div>
        <div className="my_container">
          <div className="text_container">
            <h2 className="heading">
              Contact <br />
              ASRE ENGINEERING
            </h2>
            {/* <p className="desc">
              Within this dedicated section, you will find a wealth of
              descriptive details to assist you in pinpointing our precise
              location. Additionally, we provide essential contact information,
              including our contact number for direct communication, our
              operational days and hours to ensure timely interaction, and an
              email address that offers another convenient means to reach out to
              us.
            </p> */}
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="box_container">
                <img
                  className="icon_img"
                  alt="icon"
                  loading="lazy"
                  src={locationicon}
                />
                <h4 className="heading">Registered Address</h4>
                <p className="desc">
                  102/A, Image Apt, Sharifa Road, Mumbra - Thane 400612,
                  Maharashtra, India.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="box_container">
                <img
                  className="icon_img"
                  alt="icon"
                  loading="lazy"
                  src={locationicon}
                />
                <h4 className="heading">Corporate Office / Workshop</h4>
                <p className="desc">
                  165/2, Near Star Garden Hall, Khardi Rd, Opp Bharat Gears
                  Ltd,Mumbra – Thane 400612 Maharashtra, India.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="box_container">
                <img
                  className="icon_img"
                  alt="icon"
                  loading="lazy"
                  src={callicon}
                />
                <h4 className="heading">Call Us On</h4>
                <p className="desc">
                  <a className="number" href="tel:+91 7208148600">
                    +91 7208148600
                  </a>
                  <br />
                  <a className="number" href="tel:+91 9323568110">
                    +91 9323568110
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="box_container">
                <img
                  className="icon_img"
                  alt="icon"
                  loading="lazy"
                  src={mailiconcontact}
                />
                <h4 className="heading">Write To Us On</h4>
                <p className="desc">
                  <a className="number" href="mailto:mail@asreengineering.com">
                    mail@asreengineering.com
                  </a>
                  <br />
                  <a className="number" href="mailto:asreengineering@gmail.com">
                    asreengineering@gmail.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="global_presence">
        <div className="my_container">
          <h2 className="heading">Global Presence</h2>
          <img
            width={1920}
            height={600}
            src={images.globalpresence.image}
            alt={images.globalpresence.alt}
            className="global_map"
            loading="lazy"
          />
          <ul className="country_list">
            {countriesArr.map((country) => (
              <li className="country_name">{country}</li>
            ))}
          </ul>
        </div>
      </section>

      <section className="contact_sec3" data-aos="fade-in">
        {width > 767 ? (
          <iframe
            title="Strainers Manufacturers in Mumbai Geo Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.4952170466995!2d73.0216004747502!3d19.173560948903415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bf13e0f69b0f%3A0x3d79aa9fc5ec5014!2sAsre%20Engineering%20-%20Strainer%20Manufacturers%20in%20Mumbai.%20Basket%20Type%20Strainers%2C%20Y%20Strainers%20%2C%20Y%20type%20Strainer.!5e0!3m2!1sen!2sin!4v1690696618871!5m2!1sen!2sin"
            width="100%"
            height="672"
            style={{ border: "0", margin: "0", padding: "0" }}
            loading="lazy"
          ></iframe>
        ) : (
          <iframe
            title="Strainers Manufacturers in Mumbai Geo Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.4952170466995!2d73.0216004747502!3d19.173560948903415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bf13e0f69b0f%3A0x3d79aa9fc5ec5014!2sAsre%20Engineering%20-%20Strainer%20Manufacturers%20in%20Mumbai.%20Basket%20Type%20Strainers%2C%20Y%20Strainers%20%2C%20Y%20type%20Strainer.!5e0!3m2!1sen!2sin!4v1690696618871!5m2!1sen!2sin"
            width="100%"
            height="363"
            style={{ border: "0", margin: "0", padding: "0" }}
            loading="lazy"
          ></iframe>
        )}
      </section>
    </>
  );
};

export default ContactUs;
