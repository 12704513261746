import React, { useEffect, useState } from "react";
import "./productlisting.scss";
import { Banner } from "../../Components/Banner/Banner";
import { homepage } from "../../helpers/constant-words";
import MetaDecorator from "../../Components/MetaDecorator/MetaDecorator";
import ImageWithFallback from "../../Components/ImageWithFallback/ImageWithFallback";
import { Link, useNavigate, useParams } from "react-router-dom";
import { findCategoryBySlug, findAllProducts } from "../../Data/productsData";

const ProductListing = () => {
  const { categorySlug } = useParams();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState({});
  let navigate = useNavigate();

  function CoreItem(props) {
    return (
      <li
        className="core_point"
        dangerouslySetInnerHTML={{ __html: props.message }}
      />
    );
  }

  useEffect(() => {
    const categoryData = findCategoryBySlug(categorySlug);

    if (!category) {
      navigate("/404");
    } else {
      const productsData = findAllProducts(categoryData?.slug);
      setCategory(categoryData);
      setProducts(productsData);
    }
  }, [categorySlug, category, navigate]);

  const productsList = products.map((item, i) => (
    <div className="product_box" key={i}>
      <div className="row">
        <div className="col-lg-4">
          <div className="imageanimate zoom-out">
            <ImageWithFallback
              src={item.image}
              fallbackSrc={item.fallImage}
              alt={item?.alt}
              className="product_img"
            />
          </div>
        </div>
        <div className="col-lg-8">
          <div className="product_details">
            <h2 className="title">{item.title}</h2>
            <p
              className="desc"
              dangerouslySetInnerHTML={{ __html: item.desc }}
            />
            {item.key != "" && <p className="features">Key Specifications:</p>}
            <ul className="feature_wrap">
              {item.key?.map((item, i) => (
                <li className="feature" key={i}>
                  <strong>{item.title} </strong>
                  {item.desc}
                </li>
              ))}
            </ul>
            <div className="cta_wrapper">
              <Link to={item?.slug}>
                <button to={item?.slug} className="common_button">
                  Know More
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [categorySlug]);

  return (
    <>
      <MetaDecorator
        metaTitle={category?.metaTitle}
        metaDesc={category?.metaDesc}
        canonicalLink={window.location.href}
      />
      <Banner
        breadcrumbWord1="Home"
        link1={homepage}
        breadcrumbWord4={category?.title}
        disabled
      />
      <section className="listing_sec1">
        <div className="my_container">
          <div className="category_text_container">
            <h1 className="title">{category?.title}</h1>
            <p
              className="desc"
              dangerouslySetInnerHTML={{ __html: category?.description }}
            />
          </div>

          <div className="products_wrapper">{productsList}</div>
          <div className="category_bottom_text_container">
            <h3 className="title">{category?.title2}</h3>
            <p
              className="desc"
              dangerouslySetInnerHTML={{ __html: category?.desc2 }}
            />
            {category?.corePoints != "" && (
              <ul className="core_wrap">
                {category?.corePoints?.map((message) => (
                  <CoreItem key={message} message={message} />
                ))}
              </ul>
            )}
            <p
              className="desc"
              dangerouslySetInnerHTML={{ __html: category?.desc3 }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductListing;
